#vimeo-block {
  position: relative;
  width: 100%;
  max-height: 720px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  overflow: hidden;
  background-color: #fbf9f2;
}
@media (min-width: 1640px) {
  #vimeo-block {
    max-height: 1000px;
  }
}
#vimeo-block .video-wrapper {
  position: relative;
  /* 16:9 Aspect Ratio */
  height: 54.5vw;
  overflow: hidden;
}
#vimeo-block .video-wrapper iframe .player {
  width: 100% !important;
  height: 100% !important;
}
#vimeo-block .video-wrapper .first-frame {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: auto;
}
#vimeo-block .video-wrapper .first-frame img {
  max-height: 54.5vw;
}
@media screen and (min-width: 768px) {
  #vimeo-block .video-wrapper {
    height: 55.25vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 55.25vw;
  }
}
@media screen and (min-width: 992px) {
  #vimeo-block .video-wrapper {
    height: 38.9vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 38.9vw;
  }
}
@media screen and (min-width: 1050px) {
  #vimeo-block .video-wrapper {
    height: 39.7vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 39.7vw;
  }
}
@media screen and (min-width: 1100px) {
  #vimeo-block .video-wrapper {
    height: 40.5vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 40.5vw;
  }
}
@media screen and (min-width: 1150px) {
  #vimeo-block .video-wrapper {
    height: 41.4vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 41.4vw;
  }
}
@media screen and (min-width: 1200px) {
  #vimeo-block .video-wrapper {
    height: 37.2vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 37.2vw;
  }
}
@media screen and (min-width: 1250px) {
  #vimeo-block .video-wrapper {
    height: 37.9vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 37.9vw;
  }
}
@media screen and (min-width: 1300px) {
  #vimeo-block .video-wrapper {
    height: 37.8vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 37.8vw;
  }
}
@media screen and (min-width: 1350px) {
  #vimeo-block .video-wrapper {
    height: 38.5vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 38.5vw;
  }
}
@media screen and (min-width: 1400px) {
  #vimeo-block .video-wrapper {
    height: 39.1vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 39.1vw;
  }
}
@media screen and (min-width: 1450px) {
  #vimeo-block .video-wrapper {
    height: 39.7vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 39.7vw;
  }
}
@media screen and (min-width: 1475px) {
  #vimeo-block .video-wrapper {
    height: 40.3vw;
  }
  #vimeo-block .video-wrapper .first-frame img {
    max-height: 40.3vw;
  }
}
#vimeo-block .logo-link {
  border-bottom: 0 !important;
}
#vimeo-block .logo-link:hover {
  border-bottom: 0 !important;
}
#vimeo-block .logo-link .logo {
  display: none;
  position: absolute;
  top: 53px;
  left: 55px;
  width: 325px;
  z-index: 2;
}
@media screen and (min-width: 992px) {
  #vimeo-block .logo-link .logo {
    display: block;
  }
}
@media screen and (min-width: 1220px) {
  #vimeo-block .logo-link .logo {
    width: 440px;
  }
}
#vimeo-block .vimeo-buttons {
  padding: 30px;
  padding-left: 26px;
  padding-top: 68px;
  padding-bottom: 40px;
}
#vimeo-block .vimeo-buttons .arrow-lists {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  position: relative;
  z-index: 2;
}
#vimeo-block .vimeo-buttons a {
  border-bottom: 0 !important;
  width: auto;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.42857143;
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 1px;
  color: #494a51;
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  #vimeo-block .vimeo-buttons a {
    font-size: 1.5vw;
  }
}
#vimeo-block .vimeo-buttons a:hover {
  text-decoration: none !important;
  border-bottom: 0 !important;
  color: #494a51;
}
#vimeo-block .vimeo-buttons li {
  margin-bottom: 6px;
  padding-left: 9px;
}
#vimeo-block .vimeo-buttons li::before {
  font-family: "Font Awesome 6 Free";
  content: "\f105";
  font-weight: 600;
  font-size: 11.3px;
  color: #494a51;
  padding-right: 9px;
}
#vimeo-block .vimeo-buttons li::marker {
  color: transparent;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  #vimeo-block .vimeo-buttons {
    padding-bottom: 35px;
  }
  #vimeo-block .vimeo-buttons .arrow-lists {
    display: flex;
    padding-left: 40px;
  }
  #vimeo-block .vimeo-buttons li {
    margin-right: 31px;
    padding-left: 9px;
  }
}
@media screen and (min-width: 992px) {
  #vimeo-block .vimeo-buttons {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 410px;
    padding-left: 20px;
    padding-bottom: 44.5px;
  }
  #vimeo-block .vimeo-buttons li {
    margin-bottom: 2px;
    padding-left: 9px;
  }
}
@media screen and (min-width: 1200px) {
  #vimeo-block .vimeo-buttons li {
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 992px) {
  #vimeo-block {
    height: 38.9vw;
    overflow: hidden;
  }
  #vimeo-block .video-wrapper {
    margin-left: 300px;
  }
}
@media screen and (min-width: 1050px) {
  #vimeo-block {
    height: 39.7vw;
  }
}
@media screen and (min-width: 1100px) {
  #vimeo-block {
    height: 40.5vw;
  }
}
@media screen and (min-width: 1150px) {
  #vimeo-block {
    height: 41.4vw;
  }
}
@media screen and (min-width: 1200px) {
  #vimeo-block {
    height: 37.2vw;
  }
}
@media screen and (min-width: 1250px) {
  #vimeo-block {
    height: 37.9vw;
  }
}
@media screen and (min-width: 1300px) {
  #vimeo-block {
    height: 37.8vw;
  }
}
@media screen and (min-width: 1350px) {
  #vimeo-block {
    height: 38.5vw;
  }
}
@media screen and (min-width: 1400px) {
  #vimeo-block {
    height: 39.1vw;
  }
}
@media screen and (min-width: 1450px) {
  #vimeo-block {
    height: 39.7vw;
  }
}
@media screen and (min-width: 1475px) {
  #vimeo-block {
    height: 40.3vw;
  }
}
@media screen and (min-width: 1220px) {
  #vimeo-block .video-wrapper {
    margin-left: 410px;
  }
}
#vimeo-block iframe {
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 177.77777778vh;
  z-index: 2;
  pointer-events: all;
}
#vimeo-block iframe * {
  padding: 0;
  margin: 0;
}
.view-editview #vimeo-block iframe {
  z-index: -1;
}
@media screen and (min-width: 992px) {
  .contenttype-lrf:not(.section-search) .logo-nav-wrapper .logo {
    display: none !important;
  }
}
