.section-image_view_fullscreen .header-wrapper {
  display: none;
}
.section-image_view_fullscreen .ui.basic.segment.content-area {
  padding-top: 0px;
  margin-top: 13px;
}
.section-image_view_fullscreen #page-search {
  padding-left: 0px;
  font-family: 'FranklinBook', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-align: center;
}
.section-image_view_fullscreen #page-search a {
  border-bottom: 0px;
  color: #9d9d9d;
  text-decoration: underline;
}
.section-image_view_fullscreen #page-search a:hover {
  border-bottom: 0px !important;
  color: black;
  text-decoration: none;
}
.section-image_view_fullscreen #page-search .home-link {
  margin-bottom: 31px;
}
.section-image_view_fullscreen #page-search p {
  margin-bottom: 21px;
  color: #000000 !important;
  font-family: 'FranklinBook', Helvetica, sans-serif !important;
  font-size: 16px;
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
  font-weight: 500;
  line-height: 18px;
}
.section-image_view_fullscreen #page-search .image-section {
  margin-top: 54px;
  max-width: 100%;
  max-height: 100%;
}
.section-image_view_fullscreen #page-search .image-section img {
  max-width: 100%;
  max-height: 100%;
}
.section-image_view_fullscreen #Footer-wrapper {
  display: none;
}
