.contenttype-exhibition .news-blogs {
  padding: 26px 0px;
  background-color: white;
}
@media (min-width: 992px) {
  .contenttype-exhibition .news-blogs {
    padding: 0px 15px !important;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: initial !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  display: flex;
  flex-direction: column;
}
.contenttype-exhibition #footer-listing-newsandblogs .blog-writer {
  padding-top: 5px;
  display: flex;
  align-items: center;
  height: 75px;
}
.contenttype-exhibition #footer-listing-newsandblogs .blog-writer p {
  display: inline-block !important;
  font-weight: 400 !important;
  font-style: normal !important;
  letter-spacing: 0 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.84) !important;
  fill: rgba(0, 0, 0, 0.84) !important;
  text-decoration: none;
  cursor: pointer;
}
.contenttype-exhibition #footer-listing-newsandblogs .blog-writer p:hover {
  text-decoration: underline;
}
.contenttype-exhibition #footer-listing-newsandblogs .writer-image-wrapper {
  margin-left: 1px;
  height: 50px !important;
  width: 50px !important;
  margin-right: 14px;
}
.contenttype-exhibition #footer-listing-newsandblogs .writer-image-wrapper img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 0px !important;
}
@media (min-width: 768px) and (max-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs {
    padding: 0px 15px;
  }
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs {
    flex-direction: row;
    padding-top: 101px;
    padding-bottom: 65px;
    padding-left: 33px;
    padding-right: 8px;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .search-items {
  flex-direction: column;
}
@media (min-width: 768px) {
  .contenttype-exhibition #footer-listing-newsandblogs .search-items {
    flex-direction: row;
    flex-basis: 50%;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .search-items {
    padding: 0px 25px;
  }
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .search-items {
    flex-basis: 75%;
    padding-right: 15px;
    padding-left: 14px;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .search-items .SeeMoreItem {
  flex-basis: 100%;
}
@media (min-width: 768px) {
  .contenttype-exhibition #footer-listing-newsandblogs .search-items .SeeMoreItem {
    flex-basis: 50%;
  }
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .search-items .SeeMoreItem {
    flex-basis: 33.3%;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .search-items img {
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1.5;
}
@media (max-width: 768px) {
  .contenttype-exhibition #footer-listing-newsandblogs .search-items img {
    width: 100%;
  }
}
@media (min-width: 992px) {
}
.contenttype-exhibition #footer-listing-newsandblogs #page-search-title {
  flex-basis: 25%;
  padding-top: 13px;
  margin-bottom: 26px;
}
@media (max-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs #page-search-title {
    margin-bottom: 8px;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs #page-search-title h1 {
  font-size: 45px;
  color: #282931;
  font-family: 'FranklinDmCp', Arial, sans-serif !important;
  text-align: center;
  margin-top: 19px !important;
  margin-bottom: 0px;
  font-weight: 400;
  text-transform: none;
  padding-bottom: 0px !important;
  letter-spacing: 0em;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs #page-search-title h1 {
    max-width: 170px;
    font-size: 4.5vw;
    line-height: 4vw;
    margin-top: 0.8vw;
    margin-bottom: 11px;
    text-align: left;
  }
}
@media (min-width: 1400px) {
  .contenttype-exhibition #footer-listing-newsandblogs #page-search-title h1 {
    font-size: 60px;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs #page-search-title h1:hover {
  text-decoration: underline;
  cursor: pointer;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs #page-search-title {
    padding-left: 15px;
    paddin-right: 15px;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs #page-search-title .more-link {
  text-align: left;
  display: none;
}
.contenttype-exhibition #footer-listing-newsandblogs #page-search-title .more-link a {
  color: #494a51;
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 1px;
  border-bottom: 0px !important;
}
.contenttype-exhibition #footer-listing-newsandblogs #page-search-title .more-link a:hover {
  text-decoration: none;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs #page-search-title .more-link {
    display: block;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .more-button {
  padding-right: 25px;
  padding-left: 25px;
  margin: 0 auto;
  display: block;
  height: auto;
  padding: 17px;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 12px;
  border: 1px solid #282931;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 0px;
  color: #282931 !important;
  cursor: pointer;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.85px !important;
  line-height: 24.2857px;
  text-shadow: 0px 0px 0px #ffffff;
  text-transform: none;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .more-button {
    display: none;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .more-button a {
  color: #282931;
}
.contenttype-exhibition #footer-listing-newsandblogs .more-button:hover {
  background-color: #282931;
}
.contenttype-exhibition #footer-listing-newsandblogs .more-button:hover a {
  color: white !important;
}
.contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem {
  padding-right: 37px;
  padding-left: 37px;
  margin-bottom: 72px;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem {
    margin-bottom: 75px;
  }
}
@media (min-width: 768px) {
  .contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem {
    padding-right: 14.5px;
    padding-left: 15.5px;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem .hero-dates {
  color: #a1a1a3 !important;
  font-size: 15px;
}
.contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem .item_title {
  font-family: 'FranklinDmCp', Arial, sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #282931;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem .item_title {
    font-family: 'FranklinMed', Arial, sans-serif;
    line-height: 25px;
    font-size: 20px;
    letter-spacing: 0em;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem .item_title:hover {
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 2px;
}
.contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem .listing-dates-wrapper {
  color: #a1a1a3;
  font-size: 15px;
  line-height: 35px;
  letter-spacing: 0.05em;
  margin-top: 19.5px;
  text-transform: uppercase;
  font-family: 'FranklinMed', Arial, sans-serif;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem .listing-dates-wrapper {
    margin-top: 15px;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .SeeMoreItem .item_description {
  margin-top: 10px;
  line-height: 30px;
  margin-bottom: 5px;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em !important;
  color: #494a51 !important;
  margin: 8px 0 !important;
  margin-top: 10px !important;
  font-size: 17.6px !important;
}
@media (min-width: 992px) {
}
.contenttype-exhibition #footer-listing-newsandblogs .social-buttons {
  margin-top: 50px;
  padding-top: 55px;
  margin-bottom: 30px;
  padding-bottom: 50px;
  display: flex;
  width: 100%;
  justify-content: center;
}
@media (min-width: 992px) {
  .contenttype-exhibition #footer-listing-newsandblogs .social-buttons {
    display: none;
  }
}
.contenttype-exhibition #footer-listing-newsandblogs .social-buttons .button {
  height: 50px;
  width: 50px;
  border: 1px solid black;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contenttype-exhibition #footer-listing-newsandblogs .social-buttons a {
  width: 20px;
  height: 27px;
  border-bottom: 0px !important;
}
.contenttype-exhibition #footer-listing-newsandblogs .social-buttons .button:hover {
  cursor: pointer;
  background-color: black;
}
.contenttype-exhibition #footer-listing-newsandblogs .social-buttons .button:hover svg {
  fill: white !important;
}
