#view #quote-block-wrapper.Quoteblock,
#page-edit #quote-block-wrapper.Quoteblock,
#view .block-editor-Quoteblock,
#page-edit .block-editor-Quoteblock {
  background-color: #f9fafb;
  position: relative;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
#view #quote-block-wrapper.Quoteblock .block.Quoteblock::before,
#page-edit #quote-block-wrapper.Quoteblock .block.Quoteblock::before,
#view .block-editor-Quoteblock .block.Quoteblock::before,
#page-edit .block-editor-Quoteblock .block.Quoteblock::before {
  z-index: 0;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid {
  flex-direction: column;
  justify-content: center;
  height: 110vh;
  padding: 50px 48px 50px !important;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text {
  width: 100%;
  text-align: center;
  padding: 0% !important;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column span,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column span,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column span,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column span {
  display: block;
  width: 100%;
  text-align: center;
  margin-left: 0% !important;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root {
  width: 100%;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner {
  display: none;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after {
  content: 'Every child is an artist. The problem is how to remain an artist once we grow up.' !important;
  padding: 25px;
  text-align: center !important;
  width: 100%;
  display: block;
  padding: 0% !important;
  font-size: 27.2px !important;
  line-height: 36px !important;
  margin-top: 0px !important;
  margin-bottom: 25px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  color: #292831;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after {
    font-size: 3vw !important;
    line-height: 4.7vw !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid h2,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid h2,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid h2 {
  padding: 0% !important;
  color: #e70518 !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 17.6px !important;
  line-height: 30px !important;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid h2 {
    font-size: 1.8vw !important;
    margin-top: 0px !important;
    line-height: 1.8vw !important;
    margin-bottom: 20px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
  padding: 0% !important;
  font-size: 27.2px !important;
  line-height: 36px !important;
  margin-top: 0px !important;
  margin-bottom: 25px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  color: #292831;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 3vw !important;
    line-height: 4.7vw !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after {
  content: 'Pablo Picasso' !important;
  padding: 0% !important;
  color: #e70518 !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 10px;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 17.6px !important;
  line-height: 30px !important;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after {
    font-size: 1.8vw !important;
    margin-top: 0px !important;
    line-height: 1.8vw !important;
    margin-bottom: 20px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child .public-DraftEditorPlaceholder-root:after {
  padding: 0% !important;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span {
  padding: 0% !important;
  color: #e70518 !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 10px;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 17.6px !important;
  line-height: 30px !important;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:first-child span {
    font-size: 1.8vw !important;
    margin-top: 0px !important;
    line-height: 1.8vw !important;
    margin-bottom: 20px !important;
  }
}
#page-edit .block-editor-Quoteblock {
  background-color: white;
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid {
  height: 90vh !important;
  background-color: #f9fafb;
}
#page-edit .block-editor-Quoteblock .block-add-button,
#page-edit .block-editor-Quoteblock .remove-block-button,
#page-edit .block-editor-Quoteblock .toolbar {
  display: none;
}
.has-sidebar #page-edit .block-editor-Quoteblock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    padding-left: 0px !important;
  }
}
