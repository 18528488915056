.listing-image-album .expandbutton {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  top: 0;
  right: 0;
  width: 47.5px;
  height: 47.5px;
  background-color: rgba(51, 51, 51, 0.6);
}
.listing-image-album #expand-svg {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  position: absolute;
  overflow: visible;
  fill: #ffffff;
  color: #dcdcdc;
}
.listing-image-album .imagethumb {
  cursor: pointer;
}
