.section-advancedsearch #main #page-document.ui.container,
.section-search #main #page-document.ui.container {
  width: auto !important;
  margin-right: calc(4% - 1px) !important;
  margin-left: calc(4% - 1px) !important;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main #page-document.ui.container,
  .section-search #main #page-document.ui.container {
    width: 750px !important;
    padding-right: 2px;
    padding-left: 2px;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .section-advancedsearch #main #page-document.ui.container,
  .section-search #main #page-document.ui.container {
    width: 970px !important;
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .section-advancedsearch #main #page-document.ui.container,
  .section-search #main #page-document.ui.container {
    width: 1170px !important;
    padding-right: 14.5px;
    padding-left: 14.5px;
  }
}
.section-advancedsearch #main #page-document.ui.container h1.documentFirstHeading,
.section-search #main #page-document.ui.container h1.documentFirstHeading {
  margin-top: 70px;
  margin-bottom: 35px !important;
  font-size: 40px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main #page-document.ui.container h1.documentFirstHeading,
  .section-search #main #page-document.ui.container h1.documentFirstHeading {
    margin-top: 20px !important;
    margin-bottom: 18px !important;
    font-size: 80px;
  }
}
@media screen and (min-width: 992px) {
  .section-advancedsearch #main #page-document.ui.container h1.documentFirstHeading,
  .section-search #main #page-document.ui.container h1.documentFirstHeading {
    margin-top: 9.5px !important;
  }
}
@media screen and (min-width: 1200px) {
  .section-advancedsearch #main #page-document.ui.container h1.documentFirstHeading,
  .section-search #main #page-document.ui.container h1.documentFirstHeading {
    margin-right: auto !important;
    margin-left: 9% !important;
  }
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main .block.search,
  .section-search #main .block.search {
    width: 90%;
    padding-top: 20px;
    margin: 0 auto;
  }
}
.section-advancedsearch #main .search-wrapper,
.section-search #main .search-wrapper {
  padding-bottom: 34px;
}
.section-advancedsearch #main .search-input-actions,
.section-search #main .search-input-actions,
.section-advancedsearch #main .react-select__indicator,
.section-search #main .react-select__indicator {
  display: none;
}
.section-advancedsearch #main .react-select__menu,
.section-search #main .react-select__menu {
  border: 1px solid #c3c3c3;
  border-top: 0px;
}
.section-advancedsearch #main .react-select__menu .react-select__option > div,
.section-search #main .react-select__menu .react-select__option > div {
  margin-left: 10px;
}
.section-advancedsearch #main .searchBlock-facets,
.section-search #main .searchBlock-facets {
  width: 100%;
  margin-top: 1px !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.section-advancedsearch #main .search-details,
.section-search #main .search-details {
  padding-left: 14px;
}
.section-advancedsearch #main .text-input-facet,
.section-search #main .text-input-facet,
.section-advancedsearch #main .select-facet,
.section-search #main .select-facet {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  margin-top: 13px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main .text-input-facet,
  .section-search #main .text-input-facet,
  .section-advancedsearch #main .select-facet,
  .section-search #main .select-facet {
    flex-direction: row;
    padding-right: 0px;
    margin-top: 15px;
  }
}
.section-advancedsearch #main .ui.header,
.section-search #main .ui.header {
  display: block !important;
  padding-bottom: 6px;
  margin-top: 18px !important;
  margin-bottom: auto !important;
  color: #404040;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 17px !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0.8px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main .ui.header,
  .section-search #main .ui.header {
    width: calc(35% - 1.5px);
    padding-bottom: 0px;
  }
}
.section-advancedsearch #main .searchBlock-facets .ui.input > input,
.section-search #main .searchBlock-facets .ui.input > input,
.section-advancedsearch #main .react-select-container > input,
.section-search #main .react-select-container > input {
  padding-top: 15.8px !important;
  padding-bottom: 15.8px !important;
  padding-left: 20px !important;
}
@media screen and (min-width: 768px) {
}
.section-advancedsearch #main .react-select__control,
.section-search #main .react-select__control {
  padding: 13px 30px 9px !important;
}
.section-advancedsearch #main .react-select__control .react-select__placeholder,
.section-search #main .react-select__control .react-select__placeholder {
  font-family: 'FranklinBook', Arial, sans-serif !important;
  font-size: 16.8px;
  letter-spacing: 0.4px;
}
.section-advancedsearch #main .searchBlock-facets .ui.input,
.section-search #main .searchBlock-facets .ui.input,
.section-advancedsearch #main .react-select-container,
.section-search #main .react-select-container {
  width: 100%;
}
.section-advancedsearch #main .searchBlock-facets .ui.input input,
.section-search #main .searchBlock-facets .ui.input input,
.section-advancedsearch #main .react-select-container input,
.section-search #main .react-select-container input,
.section-advancedsearch #main .searchBlock-facets .ui.input .react-select__value-container,
.section-search #main .searchBlock-facets .ui.input .react-select__value-container,
.section-advancedsearch #main .react-select-container .react-select__value-container,
.section-search #main .react-select-container .react-select__value-container {
  border-radius: 0px !important;
  color: #c3c3c3;
  font-family: 'StyreneBold', Arial, sans-serif !important;
  font-size: 17px !important;
  font-weight: 400;
  letter-spacing: 0em;
}
.section-advancedsearch #main .searchBlock-facets .ui.input input::-moz-placeholder, .section-search #main .searchBlock-facets .ui.input input::-moz-placeholder, .section-advancedsearch #main .react-select-container input::-moz-placeholder, .section-search #main .react-select-container input::-moz-placeholder, .section-advancedsearch #main .searchBlock-facets .ui.input .react-select__placeholder::-moz-placeholder, .section-search #main .searchBlock-facets .ui.input .react-select__placeholder::-moz-placeholder, .section-advancedsearch #main .react-select-container .react-select__placeholder::-moz-placeholder, .section-search #main .react-select-container .react-select__placeholder::-moz-placeholder {
  color: #9c9b9b !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0em;
}
.section-advancedsearch #main .searchBlock-facets .ui.input input::placeholder,
.section-search #main .searchBlock-facets .ui.input input::placeholder,
.section-advancedsearch #main .react-select-container input::placeholder,
.section-search #main .react-select-container input::placeholder,
.section-advancedsearch #main .searchBlock-facets .ui.input .react-select__placeholder::placeholder,
.section-search #main .searchBlock-facets .ui.input .react-select__placeholder::placeholder,
.section-advancedsearch #main .react-select-container .react-select__placeholder::placeholder,
.section-search #main .react-select-container .react-select__placeholder::placeholder {
  color: #9c9b9b !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0em;
}
.section-advancedsearch #main .react-select__control,
.section-search #main .react-select__control,
.section-advancedsearch #main .react-select__control:hover,
.section-search #main .react-select__control:hover {
  padding: 11px 20px;
  border: 1px solid #c3c3c3;
}
@media screen and (min-width: 768px) {
}
.section-advancedsearch #main .react-select__control--menu-is-open,
.section-search #main .react-select__control--menu-is-open {
  border-bottom: 0px !important;
}
.section-advancedsearch #main .react-select__placeholder,
.section-search #main .react-select__placeholder {
  color: #9c9b9b;
}
.section-advancedsearch #main .react-select__multi-value,
.section-search #main .react-select__multi-value {
  flex-direction: row-reverse;
  border-radius: 3px;
  color: #c3c3c3 !important;
}
.section-advancedsearch #main .react-select__multi-value .react-select__multi-value__label,
.section-search #main .react-select__multi-value .react-select__multi-value__label {
  padding-right: 6px;
  padding-left: 0px;
  color: #494a51;
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 20px;
}
.section-advancedsearch #main .react-select__multi-value .react-select__multi-value__remove,
.section-search #main .react-select__multi-value .react-select__multi-value__remove {
  padding-right: 2px;
}
.section-advancedsearch #main .react-select__multi-value .react-select__multi-value__remove svg,
.section-search #main .react-select__multi-value .react-select__multi-value__remove svg {
  width: 16px;
  height: 16px;
  stroke-width: 1.5px;
}
.section-advancedsearch #main .Search-main-button,
.section-search #main .Search-main-button {
  padding: 17px 29.4px 12px;
  border: 1px solid #282931;
  margin-top: 41px;
  margin-left: 10px;
  background-color: transparent;
  border-radius: 0%;
  color: #282931;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 17px !important;
  font-weight: 400 !important;
  letter-spacing: 0.85px;
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main .Search-main-button,
  .section-search #main .Search-main-button {
    margin-top: 15px !important;
  }
}
.section-advancedsearch #main .Search-main-button:hover,
.section-search #main .Search-main-button:hover {
  background-color: black;
  clip-path: none;
  color: white;
}
.section-advancedsearch #main .react-select__indicator .icon path,
.section-search #main .react-select__indicator .icon path {
  fill: var(--bonnefanten-primary) !important;
}
.section-advancedsearch #main .listing-items,
.section-search #main .listing-items {
  display: flex !important;
  flex-direction: column !important;
  padding-top: 16px;
  padding-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main .listing-items,
  .section-search #main .listing-items {
    flex-direction: row !important;
  }
}
.section-advancedsearch #main .listing-items #jaarverslag-title,
.section-search #main .listing-items #jaarverslag-title {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #b6a782;
  font-family: 'StyreneRegular', Arial, sans-serif;
  font-size: 19px;
  letter-spacing: 0em;
  line-height: 25px;
}
.section-advancedsearch #main .listing-items a,
.section-search #main .listing-items a {
  max-width: 100% !important;
  border-bottom: 0px !important;
  margin-right: 50px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #main .listing-items a,
  .section-search #main .listing-items a {
    max-width: 41.6% !important;
  }
}
@media screen and (min-width: 1200px) {
  .section-advancedsearch #main .listing-items a,
  .section-search #main .listing-items a {
    max-width: 25% !important;
  }
}
#advancedsearchblock {
  position: relative;
}
@media (min-width: 992px) {
  #advancedsearchblock .facets,
  #advancedsearchblock #extra-filters-wrapper {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
    padding-right: calc(1% - 7px);
    padding-left: calc(1% - 7px);
  }
}
#advancedsearchblock .search-input-clear-icon-button {
  display: none;
}
#advancedsearchblock .search-details {
  margin-top: 0px;
}
@media (min-width: 992px) {
  #advancedsearchblock .search-details {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
    padding-right: calc(1% - 7px);
    padding-left: calc(1% - 7px);
  }
}
#advancedsearchblock #search-page {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
  padding: 62px 92px 60px 106px;
  padding-right: 107px !important;
  padding-left: 107px !important;
  margin-top: -30px;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
#advancedsearchblock #search-page .searchbar {
  width: 95% !important;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 2;
}
@media (min-width: 500px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: 1%;
    padding-left: 1%;
  }
}
@media (min-width: 768px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 992px) {
  #advancedsearchblock #search-page .searchbar {
    width: 86% !important;
    padding-right: 4%;
    padding-left: 1%;
  }
}
@media (min-width: 1200px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: calc(1% - 7px);
    padding-left: calc(1% - 7px);
  }
}
@media (min-width: 1400px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: calc(1% - 12px);
    padding-left: calc(1% - 12px);
  }
}
@media screen and (min-width: 768px) {
  #advancedsearchblock #search-page {
    padding-top: 10px;
    margin-top: 25px !important;
    margin-right: 13.5px;
    margin-bottom: 32px;
    margin-left: 13.5px;
  }
}
@media screen and (min-width: 992px) {
  #advancedsearchblock #search-page {
    padding-top: 41px;
    padding-right: 13px;
    padding-left: 12px !important;
    margin-top: 5px !important;
    margin-right: 5%;
    margin-bottom: 60px;
    margin-left: 5%;
  }
}
#advancedsearchblock .searchbar {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .searchbar {
    flex-direction: row;
  }
}
#advancedsearchblock .text-input-facet {
  flex-grow: 1;
}
#advancedsearchblock .text-input-facet .ui.input {
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .text-input-facet .ui.input {
    margin-bottom: 0px;
  }
}
#advancedsearchblock .text-input-facet input {
  width: 100% !important;
  padding: 19px 18px 15px;
  border: 1px solid #c3c3c3 !important;
  border-radius: 0%;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.05em;
}
#advancedsearchblock .text-input-facet input::-moz-placeholder {
  color: #282931;
  font-size: 16px;
  opacity: 0.7;
}
#advancedsearchblock .text-input-facet input::placeholder {
  color: #282931;
  font-size: 16px;
  opacity: 0.7;
}
#advancedsearchblock .Search-main-button {
  padding: 18px 28px 12px;
  border: 1px solid #282931;
  background-color: white !important;
  border-radius: 0%;
  color: var(--bonnefanten-bg) !important;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 17px;
  font-weight: 400 !important;
  letter-spacing: 0.05em;
  line-height: 1.4;
}
@media screen and (max-width: 992px) {
  #advancedsearchblock .Search-main-button {
    margin: 0px !important;
  }
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .Search-main-button {
    width: 20%;
    padding: 14px 28px 10px;
  }
}
@media screen and (min-width: 1300px) {
  #advancedsearchblock .Search-main-button {
    margin-right: 0px;
  }
}
#advancedsearchblock .Search-main-button:hover {
  background-color: #282931 !important;
  color: white !important;
  cursor: pointer;
}
#advancedsearchblock .react-select__indicator .icon path {
  fill: var(--bonnefanten-primary) !important;
}
#advancedsearchblock .react-select__menu {
  width: 99%;
}
#advancedsearchblock .link-to-search {
  display: none;
  margin-top: 25px;
  margin-bottom: 0px;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .link-to-search {
    margin-top: 2px;
  }
}
#advancedsearchblock .link-to-search a {
  border-bottom: 1px solid #b6a782;
  color: #b6a782 !important;
  font-size: 16px;
  letter-spacing: 0em;
  text-decoration: none;
  text-decoration: none !important;
}
#advancedsearchblock #page-listing {
  margin-top: 35px;
}
@media screen and (min-width: 1200px) {
  .hide-top-image .documentDescription {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
}
