#Tickets {
  padding-top: 50px;
  padding-bottom: 12px;
  background-color: #fff;
}
#Tickets .Header {
  margin-bottom: 10px;
}
#Tickets h3.Header {
  font-family: 'FranklinDmCp', Arial, sans-serif;
  font-size: 48px;
  color: #282931;
  letter-spacing: 0em;
  line-height: 55px;
  font-weight: 400;
  margin-top: 45px;
  text-transform: none;
  text-align: center;
}
#Tickets .buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 20px;
  text-align: center;
}
#Tickets .buttons .button {
  background-color: white;
  margin: 0px 10px !important;
  padding: 17px 28px 12px;
  color: black;
  width: 200px;
  font-family: 'FranklinMed', Arial, Helvetica;
  letter-spacing: 0.85px;
  font-size: 17px;
  line-height: 36px;
  border: 1px solid black;
}
#Tickets .buttons .button:hover {
  background-color: black !important;
  color: white;
  cursor: pointer;
}
@media (max-width: 992px) {
  #Tickets {
    margin-bottom: 12px;
  }
  #Tickets h3.Header {
    font-size: 28.8px;
    margin-bottom: 40px;
  }
}
@media (max-width: 645px) {
  #Tickets {
    margin-bottom: 5px;
  }
  #Tickets h3.Header {
    margin-bottom: 28px;
  }
  #Tickets .buttons {
    flex-direction: column;
    align-items: center;
  }
  #Tickets .buttons .button {
    margin-bottom: 20px !important;
  }
}
