#page-document .collectionbrowseview,
#page-edit .collectionbrowseview {
  background-color: #fbf9f2;
  margin-left: 0px;
  margin-right: 0px;
  height: auto;
  padding-top: 40px;
  padding-bottom: 50px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#page-document .collectionbrowseview .buttomline,
#page-edit .collectionbrowseview .buttomline {
  display: none;
}
@media screen and (min-width: 768px) {
  #page-document .collectionbrowseview,
  #page-edit .collectionbrowseview {
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  #page-document .collectionbrowseview,
  #page-edit .collectionbrowseview {
    padding-top: 69px;
    padding-bottom: 50px;
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 1400px) {
  #page-document .collectionbrowseview .listing-wrapper,
  #page-edit .collectionbrowseview .listing-wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-right: 1px;
    max-width: calc(80% + 65px);
  }
}
#page-document .collectionbrowseview .slick-arrow,
#page-edit .collectionbrowseview .slick-arrow {
  z-index: 1;
}
#page-document .collectionbrowseview .slick-prev,
#page-edit .collectionbrowseview .slick-prev {
  left: 5px;
  height: 35px;
  width: 58px;
  top: calc(50% - 8px);
}
@media screen and (min-width: 768px) {
  #page-document .collectionbrowseview .slick-prev,
  #page-edit .collectionbrowseview .slick-prev {
    left: -14px;
  }
}
@media screen and (min-width: 992px) {
}
#page-document .collectionbrowseview .slick-prev:before,
#page-edit .collectionbrowseview .slick-prev:before {
  font-family: 'FranklinMed';
  font-size: 30px !important;
  color: #282931;
}
#page-document .collectionbrowseview .slick-prev path,
#page-edit .collectionbrowseview .slick-prev path {
  color: #4d4d4d !important;
  font-size: 40px !important;
}
#page-document .collectionbrowseview .slick-next,
#page-edit .collectionbrowseview .slick-next {
  right: 5px;
  height: 35px;
  width: 58px;
  top: calc(50% - 8px);
}
@media screen and (min-width: 768px) {
  #page-document .collectionbrowseview .slick-next,
  #page-edit .collectionbrowseview .slick-next {
    right: -14px;
  }
}
@media screen and (min-width: 992px) {
}
#page-document .collectionbrowseview .slick-next:before,
#page-edit .collectionbrowseview .slick-next:before {
  font-family: 'FranklinMed';
  font-size: 30px !important;
  color: #282931;
}
#page-document .collectionbrowseview .slick-next path,
#page-edit .collectionbrowseview .slick-next path {
  color: #4d4d4d !important;
  font-size: 40px !important;
}
#page-document .collectionbrowseview .plone-item-card-link .listing-image,
#page-edit .collectionbrowseview .plone-item-card-link .listing-image {
  width: 100%;
  display: block;
}
#page-document .collectionbrowseview .plone-item-card-link .listing-image img,
#page-edit .collectionbrowseview .plone-item-card-link .listing-image img {
  width: 100%;
}
#page-document .collectionbrowseview .collection-slider-template .collectie-header,
#page-edit .collectionbrowseview .collection-slider-template .collectie-header {
  padding-bottom: 0px;
}
@media screen and (min-width: 768px) {
  #page-document .collectionbrowseview .collection-slider-template .collectie-header,
  #page-edit .collectionbrowseview .collection-slider-template .collectie-header {
    padding-bottom: 1px;
  }
}
#page-document .collectionbrowseview .collection-slider-template .collectie-header .collectie_online,
#page-edit .collectionbrowseview .collection-slider-template .collectie-header .collectie_online {
  font-size: 16px;
  font-family: 'FranklinMed', Arial, sans-serif;
  letter-spacing: 0.03em;
  text-align: center;
  padding-top: 22px;
  margin-bottom: 0px;
}
@media screen and (min-width: 922px) {
  #page-document .collectionbrowseview .collection-slider-template .collectie-header .collectie_online,
  #page-edit .collectionbrowseview .collection-slider-template .collectie-header .collectie_online {
    line-height: 16px;
  }
}
#page-document .collectionbrowseview .collection-slider-template .collectie-header .browse_collection,
#page-edit .collectionbrowseview .collection-slider-template .collectie-header .browse_collection {
  display: block;
  font-size: 45px !important;
  margin-left: 40px;
  margin-right: 40px;
  line-height: 1;
  text-align: center !important;
  margin-top: 1px;
  margin-bottom: 40px;
  font-family: 'FranklinDmCp', Arial, sans-serif;
  color: #282931;
  letter-spacing: 0em;
  font-weight: 400;
  text-transform: none;
  border-bottom: 0 !important ;
}
@media screen and (min-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .collectie-header .browse_collection,
  #page-edit .collectionbrowseview .collection-slider-template .collectie-header .browse_collection {
    font-size: 80px !important;
    margin-bottom: 20px;
  }
}
#page-document .collectionbrowseview .collection-slider-template .collectie-header .browse_collection:hover,
#page-edit .collectionbrowseview .collection-slider-template .collectie-header .browse_collection:hover {
  text-decoration: underline;
  cursor: pointer;
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper {
  padding-bottom: 30px;
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .image-description-wrapper,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .image-description-wrapper {
  position: relative;
}
@media screen and (min-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .title-description,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .title-description {
    width: 100%;
    display: none;
    position: absolute !important;
    padding-bottom: 15px;
    padding-left: 25px;
    bottom: 0px;
    left: 0px;
  }
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .image-description-wrapper:hover .title-description,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .image-description-wrapper:hover .title-description {
  display: block;
}
@media screen and (min-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .image-description-wrapper:hover .title-description,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .image-description-wrapper:hover .title-description {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 300%) !important;
  }
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slider,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slider {
  position: relative !important;
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .description p,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .description p {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 17px;
}
@media screen and (min-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .description p,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .description p {
    color: white;
  }
}
@media (max-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .item-description.dash,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .item-description.dash {
    display: none;
  }
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title {
  margin-top: 16px !important;
  margin-bottom: 0px !important;
  font-size: 1.35em;
  line-height: 2px;
  font-family: 'FranklinMed', Arial, sans-serif !important;
  color: #494A51;
  letter-spacing: 0em;
  font-weight: 400;
  text-transform: none;
}
@media screen and (max-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title {
    padding-top: 0.1px;
  }
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title p,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title p {
  font-family: 'FranklinMed', Arial, sans-serif !important;
  border-bottom: 0px !important;
  font-size: 20px;
  color: #282931;
  margin-bottom: 1px !important;
  margin-top: 8px;
  line-height: 30px;
}
@media screen and (min-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title p,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title p {
    color: white;
    font-size: 23px;
  }
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title:hover p,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .plone-item-title:hover p {
  text-decoration: underline !important;
  text-underline-offset: 5px !important;
  text-decoration-thickness: 0.3px !important;
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card {
  height: 135vw;
  min-height: 130vw;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card {
    height: 82vw;
    min-height: 500px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card {
    height: 45vw;
  }
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card-link,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .plone-item-card-link {
  border-bottom: 0px !important;
  height: 100%;
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content {
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content .listing-image,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content .listing-image {
  width: auto !important;
  flex: 0 1 auto;
  border-bottom: 0px !important;
}
@media screen and (max-width: 992px) {
  #page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content .listing-image,
  #page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content .listing-image {
    max-height: calc(100% - 100px);
  }
}
#page-document .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content .listing-image img,
#page-edit .collectionbrowseview .collection-slider-template .content-wrapper .slick-slide .content .listing-image img {
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#page-edit .collectionbrowseview {
  max-width: 60vw !important;
}
