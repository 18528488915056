#view #quote-block-wrapper.ImageAndTextBlock,
#page-edit #quote-block-wrapper.ImageAndTextBlock,
#view .block-editor-ImageAndTextBlock,
#page-edit .block-editor-ImageAndTextBlock {
  background-color: #fff;
  position: relative;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding: 0%;
}
#view #quote-block-wrapper.ImageAndTextBlock .block.ImageAndTextBlock::before,
#page-edit #quote-block-wrapper.ImageAndTextBlock .block.ImageAndTextBlock::before,
#view .block-editor-ImageAndTextBlock .block.ImageAndTextBlock::before,
#page-edit .block-editor-ImageAndTextBlock .block.ImageAndTextBlock::before {
  z-index: 0;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid {
  justify-content: center;
  padding-bottom: 23px;
  margin: 0% !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid {
    flex-direction: row;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    padding-left: calc(5vw + 2px);
    padding-right: calc(5vw + 2px);
    padding-top: 5.21vw;
    padding-bottom: calc(5.21vw);
    align-items: start;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center {
    padding-top: 0px !important;
  }
}
@media (min-width: 1600px) {
}
@media (min-width: 2200px) {
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
  width: 100% !important;
  height: auto;
  padding: 0% !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper- {
  margin: 0px;
  padding: 0px !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img {
  max-width: 100%;
  margin: 0%;
  transform: none;
  width: 100%;
  height: auto;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit {
  left: 0% !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit sub,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit sub,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit sub,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit sub {
  color: #282931;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a {
  color: #282931;
  border-bottom: 1px solid #282931 !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a:hover,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a:hover,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a:hover,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit a:hover {
  color: #282931;
}
@media screen and (max-width: 786px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit {
    position: static !important;
    padding-left: 36px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    grid-row: 1 / span 2;
    padding-left: 0px !important;
    padding-right: 1.04vw !important;
    padding-left: 6px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-top: 0px !important;
    padding-right: calc(1.04vw - 0px) !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(3),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(3),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(3),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(3) {
    grid-column: 2;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
  display: flex !important;
  align-self: flex-start;
  padding-bottom: 0px !important;
  padding-left: 31px !important;
  padding-right: 35px !important;
}
@media (min-width: 550px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: 33px !important;
    padding-right: 33px !important;
  }
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: 68px !important;
    padding-right: 65px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    width: 100%;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2 {
  padding: 0% !important;
  color: #e70518 !important;
  margin-top: 14px !important;
  margin-bottom: 15px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 16px !important;
  line-height: 30px !important;
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2 {
    padding-top: 8px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 1.6vw !important;
    margin-top: 8px !important;
    padding-top: 0px !important;
    margin-bottom: 15px !important;
    margin-left: 0px !important;
    line-height: 17.84px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2 {
    margin-top: 0px !important;
    line-height: 1.8vw !important;
    margin-bottom: 37px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 1.5vw !important;
    margin-bottom: 38px !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text {
  align-self: end !important;
  padding-bottom: 0% !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text {
    padding-top: 10% !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span {
  padding: 0% !important;
  color: #e70518;
  margin-top: 14px !important;
  margin-bottom: 15px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 16px !important;
  line-height: 30px !important;
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span {
    padding-top: 8px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span {
    font-size: 1.6vw !important;
    margin-top: 8px !important;
    padding-top: 0px !important;
    margin-bottom: 15px !important;
    margin-left: 0px !important;
    line-height: 17.84px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span {
    margin-top: 0px !important;
    line-height: 1.8vw !important;
    margin-bottom: 30px !important;
    margin-top: 10px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text span {
    font-size: 1.5vw !important;
    margin-bottom: 30px !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p::after,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p::after,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p::after,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text p::after,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text .public-DraftStyleDefault-block span span::after,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text .public-DraftStyleDefault-block span span::after,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text .public-DraftStyleDefault-block span span::after,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .grid-block-text .public-DraftStyleDefault-block span span::after {
  content: "\00a0\00a0\00a0\00a0\00a0———";
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text {
  align-self: start !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text {
    padding-bottom: 10% !important;
    padding-top: 0% !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span {
  padding: 0% !important;
  font-size: 16px !important;
  line-height: 30px !important;
  margin-top: 0px !important;
  margin-bottom: 35px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  color: #292831 !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span {
    font-size: 2vw !important;
    line-height: 1.45em !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text p:not(.image),
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text .DraftEditor-editorContainer,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text span {
    font-size: 2vw !important;
    line-height: 1.45em !important;
    margin-top: 8px !important;
    margin-bottom: 27px !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text ::after,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text ::after,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text ::after,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ~ .column.grid-block-text ::after {
  content: "";
}
#page-edit .block-editor-ImageAndTextBlock {
  background-color: white;
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-ImageAndTextBlock .block-add-button,
#page-edit .block-editor-ImageAndTextBlock .remove-block-button,
#page-edit .block-editor-ImageAndTextBlock .toolbar {
  display: none;
}
#page-edit .no-image-wrapper .ui.input {
  overflow: hidden;
}
.has-sidebar #page-edit .block-editor-ImageAndTextBlock {
  background-color: white;
}
.has-sidebar #page-edit .block-editor-ImageAndTextBlock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    padding-left: 0px !important;
  }
}
