/* Enables customization of addons */
/* Helper to load variables */
#sidebar .image-wrapper {
  position: relative;
  height: 100px;
  margin-top: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
}
#sidebar .image-wrapper .ui.button.remove-image {
  position: absolute;
  top: 7px;
  right: 7px;
  margin: 0;
  box-shadow: none;
}
#sidebar .image-wrapper .ui.button.remove-image:hover {
  background-color: transparent !important;
}
#sidebar .image-wrapper .ui.button.remove-image svg {
  padding: 2px;
  border: 1px solid #e4e8ec;
  margin: 0;
  background: #f3f5f7;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  opacity: 1;
}
#sidebar .align-widget .eight.wide.column > div {
  display: flex !important;
  align-items: center;
}
#sidebar .align-widget .ui.icon.button {
  padding: 4px !important;
  margin-left: 4px !important;
  border-radius: 1px !important;
}
#sidebar .align-widget .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
#sidebar .align-widget .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
#sidebar .align-widget .ui.active.basic.icon.button {
  border: 1px solid #2996da;
  background: transparent !important;
}
.slide-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (max-width: 600px) {
}
.slider-wrapper {
  position: relative;
  overflow: hidden;
  width: 0;
  min-width: 100%;
  height: 56.26vw !important;
}
@media (max-width: 600px) {
}
.slider-wrapper .slider-slide .slide-img {
  height: 56.26vw !important;
  background-size: cover;
  background-position: center;
}
.slider-wrapper .slider-slide .slider-caption {
  color: #ffffff;
  font-size: 76px;
  max-width: 100%;
  margin-bottom: 0px;
  line-height: 80px;
  margin-top: 0px;
}
@media (max-width: 600px) {
}
.slider-wrapper .slider-slide .slide-body {
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.slider-wrapper .slider-slide .slide-body a {
  text-decoration: none !important;
}
.slider-wrapper .slider-slide .slide-body .slide-title {
  color: #ffffff99;
  font-family: 'UniversCn', Arial, sans-serif;
  font-size: 19.008px;
  letter-spacing: 0em;
  line-height: 25px;
  text-transform: none !important;
  margin-top: unset !important;
  margin-bottom: unset !important;
  padding-right: 3px;
  text-decoration: none;
}
@media (max-width: 768px) {
  .slider-wrapper .slider-slide .slide-body .slide-title {
    font-size: 1rem !important;
    letter-spacing: 0.05em !important;
    line-height: 1rem !important;
  }
}
.slider-wrapper .slider-slide .slide-body .slide-description,
.slider-wrapper .slider-slide .slide-body .slide-description > p {
  color: #ffffff99;
  font-family: 'UniversCn', Arial, sans-serif;
  font-size: 19.008px;
  letter-spacing: 0.9504px;
  line-height: 25px;
  text-transform: none;
  margin-top: unset !important;
  margin-bottom: unset !important;
  padding-right: 3px;
}
@media (max-width: 768px) {
  .slider-wrapper .slider-slide .slide-body .slide-description,
  .slider-wrapper .slider-slide .slide-body .slide-description > p {
    font-size: 1rem !important;
    letter-spacing: 0.05em !important;
    line-height: 1rem !important;
  }
}
.slider-wrapper .slider-slide .slide-body > a {
  color: #fff;
}
.slider-wrapper .slider-slide .slide-copyright {
  color: #ffffff99;
  font-family: 'UniversCn', Arial, sans-serif;
  font-size: 19.008px;
  letter-spacing: 0.9504px;
  line-height: 25px;
  text-transform: none;
  margin-top: unset !important;
  margin-bottom: unset !important;
  padding-right: 3px;
}
.slider-wrapper .slider-slide .slide-copyright p {
  color: #ffffff99;
  font-family: 'UniversCn', Arial, sans-serif;
  font-size: 19.008px;
  letter-spacing: 0.9504px;
  line-height: 25px;
  text-transform: none;
  margin-top: unset !important;
  margin-bottom: unset !important;
}
@media (max-width: 768px) {
  .slider-wrapper .slider-slide .slide-copyright p {
    color: #ffffff99;
    font-family: 'UniversCn', Arial, sans-serif;
    text-transform: none;
    margin-top: unset !important;
    margin-bottom: unset !important;
    font-size: 1rem !important;
    letter-spacing: 0.05em !important;
    line-height: 1rem !important;
  }
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-custom-left-nav,
.image-gallery-custom-right-nav {
  top: unset;
  bottom: -2.5rem;
}
@media screen and (max-width: 600px) {
  .image-gallery-custom-left-nav,
  .image-gallery-custom-right-nav {
    bottom: 100px !important;
  }
}
@media screen and (max-width: 480px) {
  .image-gallery-custom-left-nav,
  .image-gallery-custom-right-nav {
    bottom: 140px !important;
  }
}
.image-gallery-fullscreen-button:hover::before,
.image-gallery-custom-left-nav:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-custom-right-nav:hover::before {
  color: #fff;
}
.image-gallery-slides {
  white-space: normal !important;
}
.slider-slide {
  position: relative;
}
.slider-arrow {
  position: relative;
  right: 0;
  bottom: 56%;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}
.slider-arrow .left-arrow,
.slider-arrow .right-arrow {
  position: absolute;
  border: none;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: #000000;
  color: #404040;
  opacity: 0.6;
}
@media (max-width: 600px) {
  .slider-arrow .left-arrow,
  .slider-arrow .right-arrow {
    width: 60px !important;
    height: 60px !important;
  }
}
.slider-arrow .left-arrow {
  left: 0px;
}
.slider-arrow .right-arrow {
  right: 0px;
}
@media (max-width: 600px) {
}
.full-width {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: initial !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
.slick-prev:before,
.slick-next:before {
  color: #ccc;
}
.slick-slider {
  position: static !important;
}
@media only screen and (min-width: 768px) {
  .has-toolbar .slider-wrapper,
  .has-toolbar .slider-slide .slide-img {
    width: calc(100vw - var(--scrollbarWidth) - 40px) !important;
    padding-left: 40px;
    padding-right: 40px;
  }
  .has-toolbar-collapsed .slider-wrapper,
  .has-toolbar-collapsed .slider-slide .slide-img {
    width: calc(100vw - var(--scrollbarWidth) - 10px) !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .view-editview.has-sidebar.has-toolbar .slider-wrapper,
  .view-editview.has-sidebar.has-toolbar .slider-slide .slide-img {
    width: calc(100vw - var(--scrollbarWidth) - 220px) !important;
    padding-left: 230px;
    padding-right: 220px;
  }
  .view-editview.has-sidebar.has-toolbar-collapsed .slider-wrapper,
  .view-editview.has-sidebar.has-toolbar-collapsed .slider-slide .slide-img {
    width: calc(100vw - var(--scrollbarWidth) - 195px) !important;
    padding-left: 200px;
    padding-right: 195px;
  }
  .view-editview.has-sidebar-collapsed.has-toolbar .slider-wrapper,
  .view-editview.has-sidebar-collapsed.has-toolbar .slider-slide .slide-img {
    width: calc(100vw - var(--scrollbarWidth) - 50px) !important;
    padding-left: 55px;
    padding-right: 50px;
  }
  .view-editview.has-sidebar-collapsed.has-toolbar-collapsed .slider-wrapper,
  .view-editview.has-sidebar-collapsed.has-toolbar-collapsed .slider-slide .slide-img {
    width: calc(100vw - var(--scrollbarWidth) - 20px) !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
