.block.form .ui.segment {
  border: 0px;
  box-shadow: none;
  margin-top: 0px;
  padding-top: 0px;
  padding-left: 14px;
  margin-top: 2.2rem !important;
}
.block.form .ui.segment .column {
  padding-left: 0px;
  padding-right: 0px;
}
.block.form .ui.segment .column .stretched.row {
  padding-bottom: 0% !important;
}
.block.form .inline.field .wrapper {
  border-bottom: 0px;
}
.block.form .ui.form .ui.input input,
.block.form .css-1ard45z-control,
.block.form .css-t95vk8-control,
.block.form textarea {
  border: 1px solid #c3c3c3 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.block.form .checkbox-group,
.block.form .radio-group {
  display: flex;
}
.block.form .checkbox-group .checkbox-item,
.block.form .radio-group .checkbox-item,
.block.form .checkbox-group .radio-button,
.block.form .radio-group .radio-button {
  padding-right: 10px;
}
.block.form h3 {
  border-bottom: 1px solid #C7D5D8;
  margin-top: 0px !important;
  font-size: 21px !important;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif !important;
  font-weight: 100;
  margin-bottom: 18px !important;
}
@media (max-width: 768px) {
  .block.form h3 {
    margin-bottom: 24px !important;
  }
}
.block.form p em {
  font-size: 14px;
  color: #666666;
}
.block.form .row {
  padding-bottom: 13.5px !important;
}
.block.form .row:first-child {
  padding-bottom: 18px !important;
}
@media (max-width: 768px) {
  .block.form .row:first-child {
    padding-bottom: 7px !important;
  }
}
.block.form label {
  font-size: 18px !important;
}
.block.form input,
.block.form .react-select__value-container {
  padding-left: 10px !important;
  height: 53px !important;
}
.block.form textarea {
  padding-left: 10px !important;
  padding-top: 10px !important;
  min-height: 145px !important;
}
.block.form .center.aligned.column {
  text-align: end !important;
}
.block.form .static-text {
  margin-bottom: 0rem !important;
}
.block.form .static-text p {
  line-height: 20px !important;
}
.block.form ul.DayPicker_weekHeader_ul {
  padding-left: 0px !important;
}
.block.form button {
  background-color: white !important;
  height: 55px;
  color: black !important;
  border: 1px solid black;
  padding-top: 17px;
  padding-bottom: 12px;
  margin: 10px 20px !important;
  font-family: 'FranklinMed', Arial, sans-serif !important;
  letter-spacing: 0.05em;
  font-size: 17px !important;
  text-align: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-radius: 0% ;
  font-weight: lighter;
}
.block.form button:hover {
  background-color: #282931 !important;
  border: #282931 !important;
  color: white !important;
}
.block.form .ui.grid > .row > .four.wide.column {
  width: 40% !important;
}
.block.form .ui.grid > .row > .eight.wide.column {
  width: 60% !important;
  padding-left: 2px;
}
.block.form .ui.form input[type=text],
.block.form textarea,
.block.form .react-select__placeholder,
.block.form input {
  font-size: 16px !important;
  color: #727276 !important;
  font-family: 'FranklinBook', Arial, sans-serif !important;
}
.block.form label {
  font-size: 18px;
  margin-left: 0px;
  line-height: 24px;
}
.DateInput,
.date-input input,
#subblock-select .eight.wide.column {
  width: 187px !important;
}
.row#subblock-select .react-select__control {
  min-height: 44px !important;
  height: 44px !important;
}
.block.form .field .static-text {
  margin-top: 45px !important;
}
.block.form input::-moz-placeholder {
  color: #282931 !important;
  opacity: 0.8;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif !important;
}
.block.form input::placeholder {
  color: #282931 !important;
  opacity: 0.8;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif !important;
}
@media screen and (max-width: 992px) {
  .block.form .row {
    padding-bottom: 6px !important;
  }
  #subblock-textarea textarea,
  #subblock-textarea .wrapper {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .centered.row.row-padded-top button {
    width: 100%;
  }
  .block.form .ui.segment .column .stretched.row {
    flex-direction: column;
  }
  .block.form .column.grid .row label {
    visibility: hidden;
  }
  .block.form .column.grid .row#subblock-date label,
  .block.form .column.grid .row#subblock-select label,
  .block.form .column.grid .row#subblock-checkbox label {
    visibility: visible;
  }
  .block.form .column.grid .row#subblock-checkbox .ui.checkbox {
    padding-left: 0px;
  }
  .block.form .inline.field .wrapper {
    min-height: 50px;
  }
  .block.form .ui.grid > .row > .eight.wide.column {
    width: 100% !important;
  }
}
