.contenttype-author #page-search-artwork .pagination-wrapper a.item:not(.active) {
  border-bottom: 0px !important;
}
.contenttype-author #page-search-artwork .pagination-wrapper a.icon:hover {
  border-bottom: 0px !important;
}
.contenttype-author .content-area > main > #view {
  padding-top: 140px;
}
@media (max-width: 992px) {
  .contenttype-author .content-area > main > #view {
    padding-top: 150px;
  }
}
@media (max-width: 768px) {
  .contenttype-author .content-area > main > #view {
    padding-top: 130px;
  }
}
.contenttype-author #page-search-title {
  display: none;
}
.contenttype-author #page-search-artwork .listing-image,
.contenttype-author #page-search-artwork a.listing-image:hover {
  border-bottom: 0 !important;
}
.contenttype-author #object-block {
  padding-top: 0px;
  margin-top: -12px;
}
@media screen and (min-width: 768px) {
  .contenttype-author #object-block {
    padding-top: 8px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 992px) {
}
.contenttype-author #object-block .ui.container {
  width: auto !important;
}
.contenttype-author #object-block #page-search-artwork {
  width: auto;
  max-width: none !important;
  padding-right: 117px;
  padding-left: 117px;
  margin: 4px !important;
  margin-top: 40px !important;
}
@media screen and (min-width: 768px) {
  .contenttype-author #object-block #page-search-artwork {
    padding-right: 111px;
    padding-left: 111px;
    margin-top: 90px !important;
  }
}
@media screen and (min-width: 992px) {
  .contenttype-author #object-block #page-search-artwork {
    padding-right: 96px;
    padding-left: 96px;
    margin-top: 40px !important;
  }
}
.contenttype-author #object-block #page-search-artwork img {
  width: 100%;
}
.contenttype-author .page-search-title {
  margin-bottom: 56px;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .contenttype-author .page-search-title {
    margin-bottom: 29px;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-author .page-search-title {
    margin-bottom: 27px;
  }
}
.contenttype-author .page-search-title h1 {
  margin-top: 10px;
  color: #b6a782;
  font-size: 4vw;
}
@media screen and (max-width: 992px) {
  .contenttype-author .page-search-title h1 {
    font-size: 8vw;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-author .page-search-title h1 {
    font-size: 6vw;
  }
}
.contenttype-author .SeeMoreItem {
  height: auto;
  box-sizing: border-box;
  padding: 20px;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}
@media screen and (max-width: 768px) {
  .contenttype-author .SeeMoreItem {
    padding: 0px;
    padding-bottom: 40px;
  }
}
.contenttype-author .SeeMoreItem a {
  font-family: 'StyreneBold', Arial, sans-serif;
  text-decoration: none !important;
}
.contenttype-author .SeeMoreItem a:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: #282931 !important;
          text-decoration-color: #282931 !important;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
.contenttype-author .SeeMoreItem img {
  width: 100% !important;
  margin-bottom: 8px !important;
}
@media screen and (min-width: 768px) {
  .contenttype-author .SeeMoreItem img {
    margin-bottom: 5px !important;
  }
}
.contenttype-author .SeeMoreItem .description p {
  margin: 0 !important;
  line-height: 20px !important;
}
.contenttype-author .SeeMoreItem .item-description {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #494a51;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 12px !important;
  letter-spacing: 0em;
  line-height: 16px !important;
}
.contenttype-author .item_title {
  padding-top: 7px !important;
  padding-bottom: 6px !important;
  color: #282931;
  font-family: 'FranklinBook', Arial, sans-serif !important;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none !important;
}
.contenttype-author .item_title:hover {
  color: #282931 !important;
}
.contenttype-author #main .description-wrapper {
  padding-top: 0px !important;
  padding-bottom: 0.1px !important;
}
.contenttype-author #main .description-wrapper .author_url {
  max-width: 660px;
  padding-top: 18px;
  padding-bottom: 75px;
  margin: 0 auto;
  color: #494a51;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}
.contenttype-author #main .description-wrapper .author_url a:hover {
  border-bottom: none !important;
  color: #494a51;
}
@media (max-width: 992px) {
  .contenttype-author #main .description-wrapper .author_url {
    padding-top: 80px;
    font-size: 19.68px;
    text-align: left;
  }
}
@media (max-width: 768px) {
  .contenttype-author #main .description-wrapper .author_url {
    padding-top: 30px;
    padding-left: 117px;
    padding-right: 117px;
  }
}
.contenttype-author #main .documentDescription.author:not(.hero-description) {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-family: 'FranklinDmCp', Arial, sans-serif !important;
  font-size: 38px !important;
  font-size: 45px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  line-height: 75px !important;
  text-align: center !important;
  text-transform: none !important;
  color: #282931 !important;
}
@media screen and (min-width: 992px) {
  .contenttype-author #main .documentDescription.author:not(.hero-description) {
    margin-top: 25px !important;
    font-size: 60px !important;
  }
}
