#advancedsearchblock {
  position: relative;
}
#advancedsearchblock .advanced-search-filter-button {
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;
}
#advancedsearchblock .advanced-search-filter-button .filters-btn {
  margin-top: 15px;
  background: transparent;
  color: #494a51;
  border: 0px;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.8px;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 0.8px;
  padding-left: 5px;
}
@media (min-width: 600px) {
  #advancedsearchblock .advanced-search-filter-button .filters-btn {
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  #advancedsearchblock .advanced-search-filter-button .filters-btn {
    padding-left: 0%;
  }
}
#advancedsearchblock .advanced-search-filter-button .filters-btn:hover {
  text-decoration: none;
}
@media (min-width: 500px) {
  #advancedsearchblock .advanced-search-filter-button {
    padding-right: 1%;
    padding-left: 1%;
  }
}
@media (min-width: 768px) {
  #advancedsearchblock .advanced-search-filter-button {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 992px) {
  #advancedsearchblock .advanced-search-filter-button {
    padding-right: 1%;
    padding-left: 1%;
    width: 86% !important;
  }
}
@media (min-width: 1200px) {
  #advancedsearchblock .advanced-search-filter-button {
    padding-right: calc(1% - 7px);
    padding-left: calc(1% - 7px);
  }
}
@media (min-width: 1400px) {
  #advancedsearchblock .advanced-search-filter-button {
    padding-right: calc(1% - 12px);
    padding-left: calc(1% - 12px);
  }
}
#advancedsearchblock #search-page {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
  padding: 62px 92px 60px 106px;
  padding-right: 27px !important;
  padding-left: 27px !important;
  margin-top: -30px;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
@media (min-width: 768px) {
  #advancedsearchblock #search-page {
    padding-right: 107px !important;
    padding-left: 107px !important;
  }
}
#advancedsearchblock #search-page .block.listing::before {
  display: none;
}
#advancedsearchblock #search-page .searchbar {
  width: 86%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 500px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: 1%;
    padding-left: 1%;
  }
}
@media (min-width: 768px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 992px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: 1%;
    padding-left: 1%;
  }
}
@media (min-width: 1200px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: calc(1% - 7px);
    padding-left: calc(1% - 7px);
  }
}
@media (min-width: 1400px) {
  #advancedsearchblock #search-page .searchbar {
    padding-right: calc(1% - 12px);
    padding-left: calc(1% - 12px);
  }
}
@media screen and (min-width: 768px) {
  #advancedsearchblock #search-page {
    padding-top: 10px;
    margin-top: 57px;
    margin-right: 13.5px;
    margin-bottom: 32px;
    margin-left: 13.5px;
  }
}
@media screen and (min-width: 992px) {
  #advancedsearchblock #search-page {
    padding-top: 41px;
    padding-right: 13px;
    padding-left: 12px;
    margin-top: 5px;
    margin-right: 5%;
    margin-bottom: 60px;
    margin-left: 5%;
  }
}
#advancedsearchblock .searchbar {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .searchbar {
    flex-direction: row;
  }
}
#advancedsearchblock .text-input-facet {
  flex-grow: 1;
}
#advancedsearchblock .text-input-facet .ui.input {
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .text-input-facet .ui.input {
    margin-bottom: 0px;
  }
}
#advancedsearchblock .text-input-facet input {
  width: 100% !important;
  padding: 19px 18px 15px;
  border: 1px solid #c3c3c3 !important;
  border-radius: 0%;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.05em;
}
#advancedsearchblock .text-input-facet input::-moz-placeholder {
  color: #282931;
  font-size: 16px;
  opacity: 0.7;
}
#advancedsearchblock .text-input-facet input::placeholder {
  color: #282931;
  font-size: 16px;
  opacity: 0.7;
}
#advancedsearchblock .Search-main-button {
  padding: 18px 28px 12px;
  border: 1px solid #282931;
  background-color: white !important;
  border-radius: 0%;
  color: var(--bonnefanten-bg) !important;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-left: 0px !important;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .Search-main-button {
    width: 20%;
    padding: 14px 28px 10px;
  }
}
@media screen and (min-width: 1300px) {
}
#advancedsearchblock .Search-main-button:hover {
  background-color: #282931 !important;
  color: white !important;
  cursor: pointer;
}
#advancedsearchblock .react-select__indicator .icon path {
  fill: var(--bonnefanten-primary) !important;
}
#advancedsearchblock .react-select__menu {
  width: 99%;
}
#advancedsearchblock .link-to-search {
  display: none;
  margin-top: 25px;
  margin-bottom: 0px;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .link-to-search {
    margin-top: 2px;
  }
}
#advancedsearchblock .link-to-search a {
  border-bottom: 1px solid #b6a782;
  color: #b6a782 !important;
  font-size: 16px;
  letter-spacing: 0em;
  text-decoration: none;
  text-decoration: none !important;
}
@media screen and (min-width: 1200px) {
  .hide-top-image:not(.contenttype-event) .documentDescription {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
}
