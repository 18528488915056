.contenttype-blogwriter .content-area > main > #view {
  padding-top: 140px;
}
@media (max-width: 992px) {
  .contenttype-blogwriter .content-area > main > #view {
    padding-top: 150px;
  }
}
@media (max-width: 768px) {
  .contenttype-blogwriter .content-area > main > #view {
    padding-top: 130px;
  }
}
.contenttype-blogwriter .writer-image-wrapper {
  height: 100px !important;
  width: 100px !important;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
}
.contenttype-blogwriter .writer-image-wrapper img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (min-width: 768px) {
  .contenttype-blogwriter .writer-image-wrapper {
    margin-top: 0px;
    margin-right: 20px;
    margin-left: -120px;
    display: inline-block !important;
  }
  .contenttype-blogwriter .writer-image-wrapper img {
    margin-bottom: -30px;
  }
}
.contenttype-blogwriter #page-search-title {
  display: none;
}
.contenttype-blogwriter #page-search-artwork .listing-image,
.contenttype-blogwriter #page-search-artwork a.listing-image:hover {
  border-bottom: 0 !important;
}
.contenttype-blogwriter #object-block {
  padding-top: 0px;
  margin-top: -12px;
}
@media screen and (min-width: 768px) {
  .contenttype-blogwriter #object-block {
    padding-top: 8px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 992px) {
}
.contenttype-blogwriter #object-block #seemorenews.ui.container,
.contenttype-blogwriter #object-block #page-search-artwork.ui.container {
  width: auto !important;
}
.contenttype-blogwriter #object-block #page-search-artwork {
  width: auto;
  max-width: none !important;
  padding-right: 117px;
  padding-left: 117px;
  margin: 4px !important;
  margin-top: 40px !important;
}
@media screen and (min-width: 768px) {
  .contenttype-blogwriter #object-block #page-search-artwork {
    padding-right: 111px;
    padding-left: 111px;
    margin-top: 90px !important;
  }
}
@media screen and (min-width: 992px) {
  .contenttype-blogwriter #object-block #page-search-artwork {
    padding-right: 96px;
    padding-left: 96px;
    margin-top: 40px !important;
  }
}
.contenttype-blogwriter #object-block #page-search-artwork img {
  width: 100%;
}
.contenttype-blogwriter .page-search-title {
  margin-bottom: 56px;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .contenttype-blogwriter .page-search-title {
    margin-bottom: 29px;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-blogwriter .page-search-title {
    margin-bottom: 27px;
  }
}
.contenttype-blogwriter .page-search-title h1 {
  margin-top: 10px;
  color: #b6a782;
  font-size: 4vw;
}
@media screen and (max-width: 992px) {
  .contenttype-blogwriter .page-search-title h1 {
    font-size: 8vw;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-blogwriter .page-search-title h1 {
    font-size: 6vw;
  }
}
.contenttype-blogwriter .SeeMoreItem {
  height: auto;
  box-sizing: border-box;
  padding: 20px;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}
@media screen and (max-width: 768px) {
  .contenttype-blogwriter .SeeMoreItem {
    padding: 0px;
    padding-bottom: 40px;
  }
}
.contenttype-blogwriter .SeeMoreItem a {
  font-family: 'StyreneBold', Arial, sans-serif;
  text-decoration: none !important;
}
.contenttype-blogwriter .SeeMoreItem a:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: #282931 !important;
          text-decoration-color: #282931 !important;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
.contenttype-blogwriter .SeeMoreItem img {
  width: 100% !important;
  margin-bottom: 8px !important;
}
@media screen and (min-width: 768px) {
  .contenttype-blogwriter .SeeMoreItem img {
    margin-bottom: 5px !important;
  }
}
.contenttype-blogwriter .SeeMoreItem .description p {
  margin: 0 !important;
  line-height: 20px !important;
}
.contenttype-blogwriter .SeeMoreItem .item-description {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #494a51;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 12px !important;
  letter-spacing: 0em;
  line-height: 16px !important;
}
.contenttype-blogwriter .item_title {
  padding-top: 7px !important;
  padding-bottom: 6px !important;
  color: #282931;
  font-family: 'FranklinBook', Arial, sans-serif !important;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none !important;
}
.contenttype-blogwriter .item_title:hover {
  color: #282931 !important;
}
.contenttype-blogwriter #view .ui.pagination a.item {
  border-bottom: 0px !important;
}
.contenttype-blogwriter #view .ui.pagination a.item:hover {
  border-bottom: 0px !important;
}
.contenttype-blogwriter #main .description-wrapper {
  padding-top: 0px !important;
  padding-bottom: 0.1px !important;
}
.contenttype-blogwriter #main .description-wrapper .author_url {
  max-width: 660px;
  padding-bottom: 75px;
  padding-top: 0px;
  margin: 0 auto;
  color: #494a51;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding-right: 50px;
  padding-left: 50px;
}
@media (min-width: 768px) {
  .contenttype-blogwriter #main .description-wrapper .author_url {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.contenttype-blogwriter #main .description-wrapper .author_url a:hover {
  border-bottom: none !important;
  color: #494a51;
}
.contenttype-blogwriter .documentDescription.author:not(.hero-description) {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-family: 'FranklinDmCp', Arial, sans-serif !important;
  font-size: 38px !important;
  font-size: 45px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  line-height: 75px !important;
  text-align: center !important;
  text-transform: none !important;
}
@media screen and (min-width: 992px) {
  .contenttype-blogwriter .documentDescription.author:not(.hero-description) {
    margin-top: 25px !important;
    font-size: 60px !important;
  }
}
@media screen and (min-width: 768px) {
  .contenttype-blogwriter .documentDescription.author:not(.hero-description) {
    display: inline-block !important;
    text-align: center;
  }
}
.contenttype-blogwriter #main .documentDescription.author:not(.hero-description) {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-family: 'FranklinDmCp', Arial, sans-serif !important;
  font-size: 38px !important;
  font-size: 45px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  line-height: 75px !important;
  text-align: center !important;
  text-transform: none !important;
  color: #282931 !important;
}
@media screen and (min-width: 992px) {
  .contenttype-blogwriter #main .documentDescription.author:not(.hero-description) {
    margin-top: 25px !important;
    font-size: 60px !important;
  }
}
