#view #quote-block-wrapper.previewCollectionBlock,
#page-edit #quote-block-wrapper.previewCollectionBlock,
#view .block-editor-previewCollectionBlock,
#page-edit .block-editor-previewCollectionBlock {
  position: relative;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding: 0%;
  display: flex;
  align-items: center;
}
#view #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button button.button,
#page-edit #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button button.button,
#view .block-editor-previewCollectionBlock .preview-collection-block-button button.button,
#page-edit .block-editor-previewCollectionBlock .preview-collection-block-button button.button {
  border: 1px solid black !important;
  color: #282931 !important;
}
#view #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button button.button:hover,
#page-edit #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button button.button:hover,
#view .block-editor-previewCollectionBlock .preview-collection-block-button button.button:hover,
#page-edit .block-editor-previewCollectionBlock .preview-collection-block-button button.button:hover {
  color: white !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.previewCollectionBlock,
  #page-edit #quote-block-wrapper.previewCollectionBlock,
  #view .block-editor-previewCollectionBlock,
  #page-edit .block-editor-previewCollectionBlock {
    margin-top: calc(5.21vw + 2px) !important;
    margin-bottom: calc(5.21vw - 6px) !important;
    padding-bottom: 5.21vw;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button .preview-image-wrapper > .button,
#page-edit #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button .preview-image-wrapper > .button,
#view .block-editor-previewCollectionBlock .preview-collection-block-button .preview-image-wrapper > .button,
#page-edit .block-editor-previewCollectionBlock .preview-collection-block-button .preview-image-wrapper > .button {
  margin-left: 0px !important;
  text-transform: none !important;
  padding: 17px 28px 12px;
  line-height: 24px;
}
#view #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button .preview-image-wrapper,
#page-edit #quote-block-wrapper.previewCollectionBlock .preview-collection-block-button .preview-image-wrapper,
#view .block-editor-previewCollectionBlock .preview-collection-block-button .preview-image-wrapper,
#page-edit .block-editor-previewCollectionBlock .preview-collection-block-button .preview-image-wrapper {
  margin-top: 23px;
}
#view #quote-block-wrapper.previewCollectionBlock .text-button.btn-block.primary,
#page-edit #quote-block-wrapper.previewCollectionBlock .text-button.btn-block.primary,
#view .block-editor-previewCollectionBlock .text-button.btn-block.primary,
#page-edit .block-editor-previewCollectionBlock .text-button.btn-block.primary {
  line-height: 1.6rem;
  font-size: 19px;
  margin-top: 20px;
  padding-left: 14px;
  padding-right: 14px;
}
#view #quote-block-wrapper.previewCollectionBlock .text-button.btn-block.primary:hover,
#page-edit #quote-block-wrapper.previewCollectionBlock .text-button.btn-block.primary:hover,
#view .block-editor-previewCollectionBlock .text-button.btn-block.primary:hover,
#page-edit .block-editor-previewCollectionBlock .text-button.btn-block.primary:hover {
  background-color: var(--bonnefanten-primary-text) !important;
  cursor: pointer;
}
#view #quote-block-wrapper.previewCollectionBlock .block.previewCollectionBlock::before,
#page-edit #quote-block-wrapper.previewCollectionBlock .block.previewCollectionBlock::before,
#view .block-editor-previewCollectionBlock .block.previewCollectionBlock::before,
#page-edit .block-editor-previewCollectionBlock .block.previewCollectionBlock::before {
  z-index: 0;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid {
  justify-content: center;
  padding-bottom: 23px;
  margin: 0% !important;
  position: relative;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid {
    flex-direction: row;
    display: grid;
    grid-template-columns: minmax(0, 45fr) minmax(0, 55fr);
    padding-left: calc(8% + 5px);
    padding-right: calc(10% + 5px);
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    align-items: start;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .block.image.align.center {
    padding-top: 0px !important;
  }
}
@media (min-width: 1600px) {
}
@media (min-width: 2200px) {
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
  width: 100% !important;
  height: auto;
  padding-left: 32px !important;
  padding-right: 33px !important;
}
@media (max-width: 992px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    order: -1;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper- {
  margin: 0px;
  padding: 0px !important;
}
@media (min-width: 920px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding: 0% !important;
    padding-left: 11px !important;
    padding-right: 3px !important;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image img {
  max-width: 100%;
  margin: 0%;
  transform: none;
  width: 100%;
  height: auto;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p {
  color: var(--bonnefanten-primary-text);
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 10px;
  text-align: left !important;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub {
  color: #282931;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a {
  color: #282931;
  border-bottom: 1px solid #282931 !important;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover {
  color: #282931;
}
@media screen and (max-width: 786px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit {
    position: static !important;
    padding-left: 36px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1) {
    padding-left: 0px !important;
    padding-right: calc(1.04vw + 9.5px) !important;
    margin-right: 3px;
    padding-top: 12px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1) {
    padding-top: 8px !important;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
  display: flex !important;
  justify-content: flex-start;
  padding-top: 0px !important;
  padding-bottom: 70px !important;
}
@media (max-width: 768px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: calc(4.67vw + 6px) !important;
    padding-right: calc(4.67vw + 8px) !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p {
  flex-grow: 0;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a {
  text-decoration-thickness: 1px !important;
  text-underline-offset: 6px;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    width: 100%;
    padding-left: 32px;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
  justify-content: flex-start !important;
  height: 100%;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 0px !important;
    padding-bottom: 10% !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 15px !important;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span {
  padding: 0% !important;
  font-size: 1.25em !important;
  line-height: 30px !important;
  margin-top: 30px !important;
  margin-bottom: 35px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: var(--bonnefanten-primary-text) !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span {
    font-size: 1.8vw !important;
    line-height: 1.45em !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 8px !important;
    margin-bottom: 35px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span {
    line-height: 1.4em !important;
    margin-top: 8px !important;
    margin-bottom: 7px !important;
  }
}
@media (min-width: 1200px) {
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em {
  font-style: italic;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after {
  content: "";
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text > *,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text > *,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text > *,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid .column.grid-block-text > * {
  flex-grow: 0 !important;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h3,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h3,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h3,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h3,
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h4,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h4,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h4,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h4 {
  color: var(--bonnefanten-primary-text, #575756) !important;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description {
  font-size: 18.879999px !important;
  font-weight: 100 !important;
  letter-spacing: 0px;
  margin-top: 7px !important;
  margin-bottom: 2px !important;
  line-height: 30px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid p#preview-collection-item-description {
    font-size: 19.68px !important;
    margin-top: 11px !important;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title {
  font-size: 32px !important;
  line-height: 45px !important;
  font-family: 'FranklinDmCp', Arial, sans-serif;
  color: #282931 !important;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title {
    font-size: 4.5vw !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h2#preview-collection-item-title {
    font-size: 60px !important;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline {
  font-size: 15px !important;
  line-height: 25px !important;
  letter-spacing: 0.05em !important;
  text-transform: uppercase !important;
  font-family: 'FranklinMed', Arial, sans-serif;
  margin-top: 10px;
  margin-bottom: 3px;
  color: #282931 !important;
  font-weight: 500;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid h4#preview-collection-headline {
    margin-top: 12px;
    line-height: 35px !important;
    margin-bottom: 2px;
  }
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after {
  content: "\2013" !important;
}
#view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before,
#page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before,
#view .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before,
#page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before {
  content: "\2013" !important;
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2),
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2),
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2),
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2) {
    padding-left: 10% !important;
    padding-right: calc(5% + 5px) !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img,
  #page-edit #quote-block-wrapper.previewCollectionBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img,
  #view .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img,
  #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img {
    padding-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 3px !important;
  }
}
#page-edit .block-editor-ImageAndTextBlock {
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-ImageAndTextBlock .block-add-button,
#page-edit .block-editor-ImageAndTextBlock .remove-block-button,
#page-edit .block-editor-ImageAndTextBlock .toolbar {
  display: none;
}
#page-edit .no-image-wrapper .ui.input {
  overflow: hidden;
}
.has-sidebar #page-edit .block-editor-previewCollectionBlock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-previewCollectionBlock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-previewCollectionBlock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-previewCollectionBlock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-previewCollectionBlock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-previewCollectionBlock {
    padding-left: 0px !important;
  }
}
@media (min-width: 768px) {
  #page-document .content-container #quote-block-wrapper.previewCollectionBlock:first-child .ui.stackable.stretched.column.grid {
    margin-top: 0px !important;
  }
}
