.ui.modal.slider-modal {
  width: 95vw;
  height: 100vh;
  margin: 0% !important;
}
.ui.modal.slider-modal .content {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}
.ui.modal.slider-modal .slick-arrow {
  opacity: 0.8;
}
.ui.modal.slider-modal .slick-arrow.slick-next {
  right: 30px !important;
}
.ui.modal.slider-modal .slick-arrow::before {
  display: none;
}
.ui.modal.slider-modal .slick-arrow.slick-prev {
  left: -25px !important;
}
@media screen and (max-width: 992px) {
  .ui.modal.slider-modal .slick-arrow.slick-next {
    right: 50px !important;
  }
  .ui.modal.slider-modal .slick-arrow::before {
    display: none;
  }
  .ui.modal.slider-modal .slick-arrow.slick-prev {
    left: -10px !important;
  }
}
.ui.modal.slider-modal .modal-slide-img {
  max-height: 100%;
}
.slick-slider {
  padding-bottom: 10px;
}
.expandbutton {
  visibility: hidden;
  top: 0;
  right: 0;
  display: inline-block;
  width: 47.5px;
  height: 47.5px;
  background-color: rgba(51, 51, 51, 0.6);
  border-radius: 50%;
  pointer-events: none;
  vertical-align: middle;
}
#expand-svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: visible;
  margin: auto;
  color: #dcdcdc;
  fill: #ffffff;
}
.imagethumb {
  cursor: pointer;
}
.imagethumb:hover .expandbutton {
  visibility: visible;
}
.ui.dimmer {
  background-color: #f6f6f6 !important;
}
.ui.modal > .close {
  top: 20px !important;
  right: -10px !important;
  color: #ff0101 !important;
}
.ui.modal.slider-modal {
  background: none !important;
  box-shadow: none;
}
.ui.modal.slider-modal .slick-slide > div {
  text-align: center;
}
.ui.modal.slider-modal .slick-slide > div img {
  margin: 0 auto;
}
.ui.modal.slider-modal > .content {
  background: none !important;
}
.slide-image-count {
  text-align: center;
}
.thumbnails {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  gap: 5px;
}
.image-album .preview-image-wrapper .ui.image {
  width: 482px;
  max-width: none;
  height: auto;
  margin-top: -30px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.image-album .preview-image-wrapper .ui.image:hover {
  cursor: pointer;
}
.images-number {
  position: absolute;
  display: flex;
  width: 72px;
  height: 54px;
  align-items: center;
  background-color: #d8d8d8;
  border-radius: 5px;
  color: #FFFFFF;
  pointer-events: none;
}
.images-number div {
  width: 100%;
  text-align: center;
}
.ui.image.img-thumb {
  width: 72px !important;
  height: 54px !important;
  border-radius: 6px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.ui.image.img-thumb:hover {
  cursor: pointer;
}
.modal-slide-img {
  width: auto !important;
  max-width: none;
  max-height: calc(80vh + 22px) !important;
}
.artwork-fallback-image {
  position: relative;
  width: 482px;
  height: 730px;
  padding: 1.5em;
  margin-top: -30px;
  background-color: var(--text-color);
  border-radius: 6px;
  color: var(--background-color);
}
.artwork-fallback-image .logo {
  text-align: right;
}
.artwork-fallback-image .item-meta {
  position: absolute;
  top: 50%;
  left: 1.5em;
  transform: translateY(-50%);
}
.artwork-fallback-image .item-meta .item-title {
  margin-bottom: 0;
}
@media only screen and (max-width: 1144px) {
  .artwork-fallback-image {
    width: 100%;
    height: 490px;
  }
  .artwork-fallback-image .logo svg {
    display: inline-block;
    height: 112px !important;
  }
}
.preview-image-wrapper .button {
  width: auto;
  padding-top: 19px;
  padding-right: 28px;
  padding-bottom: 15px;
  padding-left: 28px;
  border: 1px solid #ffffff !important;
  margin-right: 2.5px !important;
  margin-left: 2.5px !important;
  background-color: transparent !important;
  color: #ffffff;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}
.preview-image-wrapper .button:hover {
  border: 1px solid #282931 !important;
  background-color: #282931 !important;
  color: #fff;
  cursor: pointer;
}
.ui.dimmer {
  padding-top: 0px !important;
}
.ui.modal > .content {
  font-size: 16px !important;
}
.top-image-align-right .preview-image-wrapper .button {
  width: auto;
  padding-top: 19px;
  padding-right: 28px;
  padding-bottom: 15px;
  padding-left: 28px;
  border: 1px solid #ffffff !important;
  margin-right: 38px !important;
  margin-left: 38px !important;
  background-color: transparent !important;
  color: #ffffff;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}
.top-image-align-right .preview-image-wrapper .button:hover {
  border: 1px solid #282931 !important;
  background-color: #282931 !important;
  color: #fff;
  cursor: pointer;
}
@media (min-width: 992px) {
  .top-image-align-right .preview-image-wrapper .button {
    color: #000000;
  }
  .top-image-align-right .preview-image-wrapper .button:hover {
    border: 1px solid #282931 !important;
    background-color: #282931 !important;
  }
  .top-image-align-right .preview-image-wrapper .button1 {
    border: 1px solid #000000 !important;
    background: transparent;
  }
}
.ui.dimmer {
  padding-top: 0px !important;
}
.ui.modal > .content {
  font-size: 16px !important;
}
