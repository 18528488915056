.view-viewview.contenttype-exhibition.horizontal-image .content-description {
  display: none;
}
@media (min-width: 992px) {
  .view-viewview.contenttype-exhibition.top-image-align-right #page-document .documentDescription {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .view-viewview.contenttype-exhibition .content-description {
    display: none;
  }
}
.view-viewview.contenttype-exhibition .tags {
  display: none;
}
.view-viewview.contenttype-exhibition .SeeMoreItem {
  padding: 20px;
}
.view-viewview.contenttype-exhibition .SeeMoreItem img {
  max-width: 100%;
}
.view-viewview.contenttype-exhibition .content-area {
  padding-bottom: 0% !important;
  margin-bottom: 0% !important;
}
.view-viewview.contenttype-exhibition .kijkverder {
  padding-bottom: 80px;
  margin-top: 30px;
  background-color: #fbf9f2;
}
.view-viewview.contenttype-exhibition #page-search-exhibition {
  width: 100% !important;
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (min-width: 768px) {
  .view-viewview.contenttype-exhibition #page-search-exhibition {
    padding-right: 27px;
    padding-left: 27px;
  }
}
@media screen and (min-width: 992px) {
  .view-viewview.contenttype-exhibition #page-search-exhibition {
    width: 1250px !important;
    padding-right: 160px;
    padding-left: 160px;
  }
}
@media screen and (min-width: 1200px) {
  .view-viewview.contenttype-exhibition #page-search-exhibition {
    padding-right: 150px;
    padding-left: 150px;
  }
}
.view-viewview.contenttype-exhibition #page-search-exhibition .description p {
  margin: 0 !important;
  line-height: 20px !important;
}
.view-viewview.contenttype-exhibition #page-search-exhibition .item-description {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #494a51;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 12px !important;
  letter-spacing: 0em;
  line-height: 16px !important;
}
.view-viewview.contenttype-exhibition #page-search-exhibition .item_title {
  padding-top: 7px !important;
  padding-bottom: 6px !important;
  color: #282931;
  font-family: 'FranklinBook', Arial, sans-serif !important;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none !important;
}
.view-viewview.contenttype-exhibition #page-search-exhibition .item_title:hover {
  color: #282931 !important;
}
.view-viewview.contenttype-exhibition #page-search-exhibition a {
  font-family: 'StyreneBold', Arial, sans-serif;
  text-decoration: none !important;
}
.view-viewview.contenttype-exhibition #page-search-exhibition a:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: #282931 !important;
          text-decoration-color: #282931 !important;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
.view-viewview.contenttype-exhibition #page-search-exhibition img {
  margin-bottom: 8px !important;
}
@media screen and (min-width: 768px) {
  .view-viewview.contenttype-exhibition #page-search-exhibition img {
    margin-bottom: 5px !important;
  }
}
.view-viewview.contenttype-exhibition #page-search-exhibition .listing-image,
.view-viewview.contenttype-exhibition #page-search-exhibition a.listing-image:hover {
  border-bottom: 0 !important;
}
.view-viewview.contenttype-exhibition #page-search-title {
  text-align: center;
}
@media screen and (min-width: 992px) {
  .view-viewview.contenttype-exhibition #page-search-title {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
.view-viewview.contenttype-exhibition #page-search-title h1 {
  padding-top: 13px;
  padding-bottom: 40px;
  margin-top: 40px !important;
  color: #282931;
  font-family: 'FranklinDmCp', Arial, sans-serif;
  font-size: 58px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 60px;
  text-transform: none;
}
.view-viewview.contenttype-exhibition #page-document .documentDescription:not(:empty) {
  margin-top: 8px !important;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-exhibition #page-document .react-swipe-container {
    padding-bottom: 50px;
  }
}
.view-viewview.contenttype-exhibition #page-document #swipe-slider .react-swipe-container {
  min-height: 400px;
}
.view-viewview.contenttype-exhibition #page-document .react-transform-component img {
  width: 100%;
  height: auto !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
}
@media screen and (min-width: 992px) {
}
@media screen and (max-width: 992px) {
}
.view-viewview.contenttype-exhibition #page-document .zoom-container {
  display: flex;
  overflow: hidden;
  height: calc(80vh - 13px) !important;
  height: 100%;
  min-height: 540px;
  align-items: center;
  justify-content: center;
  cursor: move;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-exhibition #page-document .zoom-container {
    height: 56vh !important;
  }
}
.view-viewview.contenttype-exhibition #page-document .react-transform-wrapper,
.view-viewview.contenttype-exhibition #page-document .react-transform-component,
.view-viewview.contenttype-exhibition #page-document .react-transform-component img {
  max-height: 80vh !important;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-exhibition #page-document .react-transform-wrapper,
  .view-viewview.contenttype-exhibition #page-document .react-transform-component,
  .view-viewview.contenttype-exhibition #page-document .react-transform-component img {
    max-height: 55vh !important;
  }
}
.view-viewview.contenttype-exhibition #page-document .react-transform-wrapper {
  width: 100% !important;
}
.view-viewview.contenttype-exhibition #page-document .react-transform-wrapper {
  width: 100% !important;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-exhibition #page-document .react-transform-wrapper {
    height: 55vh !important;
  }
}
.view-viewview.contenttype-exhibition #page-document .react-transform-wrapper .react-transform-component {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.view-viewview.contenttype-exhibition #page-document .react-transform-component {
  width: auto !important;
  height: 100% !important;
}
#page-document {
  padding-top: 0px;
  padding-bottom: 0px;
}
@media (min-width: 992px) {
  #page-document .buttons {
    padding-top: 17px;
    margin-top: 48px;
  }
}
#page-document .buttons .button {
  display: block;
  width: auto;
  height: auto;
  padding: 15px;
  padding-bottom: 12px;
  border: 1px solid #e6e6e6 !important;
  margin: 0 5px;
  margin-top: 0px;
  margin-bottom: 5px;
  background: white;
  border-radius: 0px;
  color: #666666;
  float: right;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 15px;
  text-shadow: 0px 0px 0px #ffffff;
  text-transform: none;
  transition: all 100ms linear;
}
#page-document .buttons .button svg,
#page-document .buttons .button path {
  width: 20px !important;
  height: 20px !important;
  stroke-width: 1px;
}
#page-document .buttons .button.share {
  position: relative;
  padding-right: 16px !important;
  padding-left: 16px !important;
}
#page-document .buttons .share-button {
  max-width: 68px;
}
#page-document .buttons .button.expand {
  width: 170px;
  padding-right: 5px;
  padding-left: 5px;
}
@media (min-width: 992px) {
  #page-document .buttons .button.expand {
    width: 200px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
#page-document .buttons .button.zoomminus {
  padding-right: 15.5px !important;
  padding-left: 15.5px !important;
  margin-right: 16px;
  margin-left: 0px;
}
#page-document .buttons .button.zoomplus {
  padding-right: 15.5px !important;
  padding-left: 15.5px !important;
  border-right: 0px !important;
  margin-right: 0px;
}
#page-document .buttons .button:hover {
  border: 1px solid #000000;
  background: #000000;
  color: #ffffff;
}
#page-document .react-transform-component > img {
  padding-top: 50px;
  padding-right: calc(12% + 5px) !important;
  padding-left: calc(12% + 5px) !important;
}
@media screen and (min-width: 768px) {
  #page-document .react-transform-component > img {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media screen and (min-width: 992px) {
  #page-document .react-transform-component > img {
    padding-top: 16px;
    padding-bottom: 20px !important;
  }
}
#page-document .leftrightbuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 12px;
  margin-top: -2px;
  text-align: center;
}
#page-document .leftrightbuttons button {
  border: 0px;
  background-color: transparent;
}
#page-document .leftrightbuttons button svg {
  width: 45px !important;
  height: 26px !important;
  fill: #737373;
}
#page-document .leftrightbuttons .paginator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
#page-document .leftrightbuttons .paginator p {
  margin: 0%;
  font-size: 16px;
}
#page-document .rawdata-section {
  overflow: hidden;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
}
@media screen and (max-width: 768px) {
  #page-document .rawdata-section {
    padding-left: 27px !important;
  }
}
#page-document .rawdata-section p {
  color: #9d9d9d;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 19.68px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 34px;
}
@media screen and (min-width: 768px) {
  #page-document .rawdata-section p {
    line-height: 36px;
  }
}
@media (max-width: 768px) {
  #page-document .rawdata-section .columnone p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  #page-document .rawdata-section .columntwo p {
    margin-top: -2px;
    font-size: 16px;
  }
}
#page-document .rawdata-section a {
  border-bottom: 0px !important;
  color: #9d9d9d;
  text-decoration: underline;
}
#page-document .rawdata-section a:hover {
  border-bottom: 0px !important;
  color: #000;
  text-decoration: none;
}
#page-document .rawdata-section ul {
  padding-left: 17px;
  margin-top: 0px;
  margin-bottom: 10px;
}
#page-document .rawdata-section ul button {
  padding-bottom: 1px;
  margin-top: 16px;
  margin-bottom: -5px;
  margin-left: -17px;
  font-size: 19.3px !important;
}
#page-document .rawdata-section p:has(li) {
  padding-bottom: 4px;
}
#page-document .rawdata-section li {
  padding-bottom: 0px;
  padding-left: 0px;
  line-height: 36.5px;
}
#page-document .rawdata-section li p {
  font-size: 19.007px !important;
  letter-spacing: 0em !important;
  line-height: 37px;
}
@media (max-width: 768px) {
  #page-document .rawdata-section li p {
    font-size: 16px !important;
  }
}
#page-document .rawdata-section li::marker {
  margin-right: 5px !important;
  color: #9d9d9d;
}
#page-document .rawdata-section.expanded {
  max-height: 30000px;
  margin-bottom: 130px;
  opacity: 1;
}
#page-document .zoom-container {
  display: flex;
  overflow: hidden;
  height: 80vh !important;
  height: 100%;
  min-height: 540px;
  align-items: center;
  justify-content: center;
  cursor: move;
}
#page-document .buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
#page-document .social-media-popup {
  position: absolute;
  right: -20px;
  bottom: 62px;
  display: flex;
  width: 90px;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#page-document .social-media-popup .popover-title {
  height: 20px;
  padding-bottom: 10px;
  margin-top: 0px;
  color: #ffffff;
  font-family: 'FranklinDmCp', Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 30px;
}
#page-document .social-media-popup a {
  border-bottom: none !important;
}
#page-document .social-media-popup a:hover {
  border-bottom: none !important;
  color: #f8f80c;
}
#page-document .social-media-popup .row {
  max-width: 70px;
}
#page-document .social-media-popup .row.facebook-row img {
  height: 33px;
  max-height: 35px !important;
}
#page-document .social-media-popup::after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  /* Center the arrow and rotate it */
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
  border-left: 10px solid transparent;
  content: '';
  transform: translateX(-50%) rotate(180deg);
  /* This creates the arrow shape */
}
.contenttype-object .portal-top {
  height: 0px;
}
.contenttype-object .ui.basic.segment.content-area {
  padding-top: 0px;
  padding-bottom: 0px !important;
  margin-top: 0px;
  margin-bottom: 0px !important;
}
.contenttype-object #page-document.ui.container {
  width: calc(100vw - 20px) !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.contenttype-object #page-document.ui.container .documentFirstHeading {
  display: none;
}
@media (max-width: 768px) {
  .contenttype-object #page-document.ui.container > * {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
.view-viewview.contenttype-exhibition #rawdata {
  width: auto;
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 100px;
}
@media (min-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata {
    margin-left: -8px;
  }
}
@media (min-width: 992px) {
  .view-viewview.contenttype-exhibition #rawdata {
    padding-right: 34.5px;
    padding-left: 34.5px;
    margin-left: -212px;
    margin-right: -200px;
  }
}
@media (min-width: 1200px) {
  .view-viewview.contenttype-exhibition #rawdata {
    margin-left: -75px;
    margin-right: -163px;
  }
}
.view-viewview.contenttype-exhibition #rawdata .data-wrapper {
  padding-right: 10px;
  padding-left: 10px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata .data-wrapper {
    width: 90%;
  }
}
.view-viewview.contenttype-exhibition #rawdata table {
  width: 100%;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .view-viewview.contenttype-exhibition #rawdata table {
    padding-right: 36px;
    padding-left: 36px;
  }
}
.view-viewview.contenttype-exhibition #rawdata table td p {
  margin: 0;
}
.view-viewview.contenttype-exhibition #rawdata .description-wrapper {
  padding-right: 4px;
  padding-left: 4px;
  margin-bottom: 73px;
}
@media (min-width: 992px) {
  .view-viewview.contenttype-exhibition #rawdata .description-wrapper {
    width: 720px;
    padding-right: 0px;
    padding-left: 0px;
    margin: 19px auto 39px;
    margin-bottom: 50px;
  }
}
.view-viewview.contenttype-exhibition #rawdata .expand-button {
  display: block;
  padding-left: 0px;
  border: 0px;
  background-color: #ffffff;
  cursor: pointer;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 19.68px;
  font-weight: 100;
  text-decoration: underline;
}
@media (max-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata .expand-button {
    font-size: 18.8px;
  }
}
.view-viewview.contenttype-exhibition #rawdata .expand-data-button {
  display: block;
  padding-left: 0px;
  border: 0px;
  margin-top: 16px;
  background-color: #ffffff;
  color: #9d9d9d;
  cursor: pointer;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 19.68px;
  font-weight: 100;
  text-decoration: underline;
}
.view-viewview.contenttype-exhibition #rawdata .expand-data-button.true {
  margin-bottom: 5px !important;
}
.view-viewview.contenttype-exhibition #rawdata .expand-data-button:hover {
  color: #000;
}
.view-viewview.contenttype-exhibition #rawdata #description.data-description {
  overflow: hidden;
  margin-bottom: 3px;
  color: #404040;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 22.8px;
  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
}
.view-viewview.contenttype-exhibition #rawdata #description.data-description p {
  margin-top: 11px;
  margin-bottom: 35px;
  color: #404040;
  font-family: 'FranklinBook', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1.18em;
  line-height: 36px;
}
@media screen and (min-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata #description.data-description p {
    font-size: 1.23em;
  }
}
.view-viewview.contenttype-exhibition #rawdata #description.data-description.false {
  position: relative;
  max-height: 243px;
}
.view-viewview.contenttype-exhibition #rawdata #description.data-description.true {
  position: relative;
  max-height: 1000px;
  margin-bottom: 15px;
}
.view-viewview.contenttype-exhibition #rawdata #description.data-description.true p + p {
  margin-top: 65px;
  margin-bottom: 47px;
}
.view-viewview.contenttype-exhibition #rawdata #description.data-description.false:after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  content: '';
  opacity: 1;
  transition: opacity 0.6s;
}
@media (max-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px !important;
  }
}
.view-viewview.contenttype-exhibition #rawdata .columnone {
  vertical-align: top;
}
@media (min-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata .columnone {
    width: 40%;
    padding: 13px 15px 0px 5px;
  }
}
@media (min-width: 992px) {
  .view-viewview.contenttype-exhibition #rawdata .columnone {
    width: 33.3%;
    padding: 13px 15px 0px 5px;
  }
}
.view-viewview.contenttype-exhibition #rawdata .columnone p {
  color: #494a51;
  letter-spacing: -0.1968px;
}
@media (max-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata .columntwo p + p {
    padding-top: 2px;
  }
}
@media (min-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata .columntwo {
    width: 60%;
    padding: 13px 0px 0px 15px;
  }
}
@media (min-width: 992px) {
  .view-viewview.contenttype-exhibition #rawdata .columntwo {
    width: 66.6%;
    padding: 13px 0px 0px 15px;
  }
}
.view-viewview.contenttype-exhibition #rawdata .columntwo p {
  color: #9d9d9d;
  letter-spacing: -0.1568px;
}
@media screen and (min-width: 768px) {
  .view-viewview.contenttype-exhibition #rawdata .columntwo p {
    letter-spacing: -0.1968px;
  }
}
.view-viewview.contenttype-exhibition #rawdata #slickcarousel-controls {
  padding-top: 22px;
  padding-bottom: 8px;
}
.view-viewview.contenttype-exhibition .react-transform-wrapper {
  width: 100% !important;
  height: 80vh !important;
}
.view-viewview.contenttype-exhibition .react-transform-wrapper .react-transform-component {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.view-editview.contenttype-exhibition #page-edit .ui.raised.segments {
  max-width: min(70vw, 1000px);
}
.view-viewview.hide-top-image.contenttype-exhibition .ui.container {
  padding-top: 106px !important;
}
@media (max-width: 768px) {
  .view-viewview.hide-top-image.contenttype-exhibition .ui.container {
    padding-top: 75px !important;
  }
}
.view-viewview.hide-top-image.contenttype-exhibition .date-indicator {
  margin-top: 34px !important;
  letter-spacing: 0.8px !important;
  font-size: 16px !important;
}
@media (min-width: 992px) {
  .view-viewview.hide-top-image.contenttype-exhibition .date-indicator {
    margin-top: 42px !important;
    font-size: 17px !important;
  }
}
.view-viewview.hide-top-image.contenttype-exhibition .date-indicator,
.view-viewview.hide-top-image.contenttype-exhibition .block-date.hero-dates {
  text-align: center;
}
.view-viewview.hide-top-image.contenttype-exhibition h1.documentFirstHeading {
  max-width: 650px !important;
  margin: 0px !important;
  padding-top: 18px !important;
  left: auto !important;
  right: auto !important;
  font-size: 40px !important;
  line-height: 35px !important;
  letter-spacing: normal !important;
  text-align: left !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  width: auto !important;
}
@media (min-width: 768px) {
  .view-viewview.hide-top-image.contenttype-exhibition h1.documentFirstHeading {
    font-size: 80px !important;
    line-height: 62px !important;
    padding-left: 15px !important;
    padding-right: 0px !important;
    text-align: center !important;
    padding-top: 17px !important;
  }
}
@media (min-width: 1200px) {
  .view-viewview.hide-top-image.contenttype-exhibition h1.documentFirstHeading {
    padding-left: 0px !important;
    margin-left: 101px !important;
    margin-right: -40px !important;
  }
}
.view-viewview.hide-top-image.contenttype-exhibition .expired .block-date.hero-dates {
  text-decoration: line-through !important;
}
.view-viewview.hide-top-image.contenttype-exhibition .block-date.hero-dates {
  color: #282931 !important;
  font-family: 'FranklinMed', Arial, sans-serif !important;
  font-size: 17px !important;
  letter-spacing: 0.05em !important;
  line-height: 35px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  margin-bottom: 10px !important;
  margin-top: -13px !important;
}
@media (max-width: 768px) {
  .view-viewview.hide-top-image.contenttype-exhibition .block-date.hero-dates {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
@media (max-width: 992px) {
  .view-viewview.hide-top-image.contenttype-exhibition .block-date.hero-dates {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .view-viewview.hide-top-image.contenttype-exhibition .block-date.hero-dates {
    margin-bottom: -2px !important;
  }
}
@media (min-width: 1200px) {
  .view-viewview.hide-top-image.contenttype-exhibition .block-date.hero-dates {
    margin-left: 101px !important;
  }
}
.view-viewview.hide-top-image.contenttype-exhibition #page-document {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
@media (min-width: 768px) {
  .view-viewview.hide-top-image.contenttype-exhibition #page-document {
    width: calc(750px - 85px) !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (min-width: 992px) {
  .view-viewview.hide-top-image.contenttype-exhibition #page-document {
    width: 750px !important;
  }
}
.view-viewview.hide-top-image.contenttype-exhibition #rawdata {
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 27px !important;
  padding-right: 27px !important;
  margin-top: 1px !important;
}
@media (min-width: 768px) {
  .view-viewview.hide-top-image.contenttype-exhibition #rawdata {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 8px !important;
  }
}
@media (min-width: 992px) {
  .view-viewview.hide-top-image.contenttype-exhibition #rawdata {
    margin-top: 3px !important;
  }
}
@media (min-width: 1200px) {
  .view-viewview.hide-top-image.contenttype-exhibition #rawdata {
    margin-top: 8px !important;
    margin-left: 94px !important;
  }
}
.view-viewview.hide-top-image.contenttype-exhibition .columntwo {
  padding-left: 85px !important;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .view-viewview.hide-top-image.contenttype-exhibition #rawdata {
    padding-left: 0px !important;
  }
  .view-viewview.hide-top-image.contenttype-exhibition .columnone {
    padding-right: 0px !important;
  }
  .view-viewview.hide-top-image.contenttype-exhibition table {
    padding-left: 0px !important;
  }
}
.expired-exhibition .block-date.hero-dates {
  text-decoration: line-through;
}
