#page-document .TeaserListingTemplate,
#page-edit .TeaserListingTemplate {
  height: auto;
  padding-top: 43px;
  padding-bottom: 0px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  min-height: 300px;
}
#page-document .TeaserListingTemplate .buttomline,
#page-edit .TeaserListingTemplate .buttomline {
  display: none;
}
@media screen and (min-width: 768px) {
  #page-document .TeaserListingTemplate,
  #page-edit .TeaserListingTemplate {
    padding-right: 40px !important;
    padding-bottom: 20px;
    padding-left: 40px !important;
    min-height: 500px;
  }
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate,
  #page-edit .TeaserListingTemplate {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 77px;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
}
#page-document .TeaserListingTemplate .listing-wrapper,
#page-edit .TeaserListingTemplate .listing-wrapper {
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (min-width: 768px) {
  #page-document .TeaserListingTemplate .listing-wrapper,
  #page-edit .TeaserListingTemplate .listing-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .listing-wrapper,
  #page-edit .TeaserListingTemplate .listing-wrapper {
    padding-top: 48px;
  }
}
#page-document .TeaserListingTemplate .slick-arrow,
#page-edit .TeaserListingTemplate .slick-arrow {
  z-index: 1;
}
#page-document .TeaserListingTemplate .slick-prev,
#page-edit .TeaserListingTemplate .slick-prev {
  left: 5px;
  height: 35px;
  width: 58px;
  top: calc(50% - 8px);
}
@media screen and (min-width: 768px) {
  #page-document .TeaserListingTemplate .slick-prev,
  #page-edit .TeaserListingTemplate .slick-prev {
    left: -14px;
  }
}
@media screen and (min-width: 992px) {
}
#page-document .TeaserListingTemplate .slick-prev:before,
#page-edit .TeaserListingTemplate .slick-prev:before {
  font-family: 'FranklinMed';
  font-size: 30px !important;
  color: #282931;
}
#page-document .TeaserListingTemplate .slick-prev path,
#page-edit .TeaserListingTemplate .slick-prev path {
  color: #4d4d4d !important;
  font-size: 40px !important;
}
#page-document .TeaserListingTemplate .slick-next,
#page-edit .TeaserListingTemplate .slick-next {
  right: 5px;
  height: 35px;
  width: 58px;
  top: calc(50% - 8px);
}
@media screen and (min-width: 768px) {
  #page-document .TeaserListingTemplate .slick-next,
  #page-edit .TeaserListingTemplate .slick-next {
    right: -14px;
  }
}
@media screen and (min-width: 992px) {
}
#page-document .TeaserListingTemplate .slick-next:before,
#page-edit .TeaserListingTemplate .slick-next:before {
  font-family: 'FranklinMed';
  font-size: 30px !important;
  color: #282931;
}
#page-document .TeaserListingTemplate .slick-next path,
#page-edit .TeaserListingTemplate .slick-next path {
  color: #4d4d4d !important;
  font-size: 40px !important;
}
#page-document .TeaserListingTemplate .plone-item-card,
#page-edit .TeaserListingTemplate .plone-item-card {
  margin-bottom: 75px;
}
@media screen and (min-width: 768px) {
  #page-document .TeaserListingTemplate .plone-item-card,
  #page-edit .TeaserListingTemplate .plone-item-card {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .plone-item-card,
  #page-edit .TeaserListingTemplate .plone-item-card {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 60px;
  }
}
#page-document .TeaserListingTemplate .plone-item-card-link .listing-image,
#page-edit .TeaserListingTemplate .plone-item-card-link .listing-image {
  width: 100%;
  display: block;
}
#page-document .TeaserListingTemplate .plone-item-card-link .listing-image img,
#page-edit .TeaserListingTemplate .plone-item-card-link .listing-image img {
  width: 100%;
}
#page-document .TeaserListingTemplate .collection-slider-template .collectie-header,
#page-edit .TeaserListingTemplate .collection-slider-template .collectie-header {
  padding-bottom: 0px;
}
@media screen and (min-width: 768px) {
}
#page-document .TeaserListingTemplate .collection-slider-template .collectie-header .collectie_online,
#page-edit .TeaserListingTemplate .collection-slider-template .collectie-header .collectie_online {
  font-size: 16px;
  font-family: 'FranklinMed', Arial, sans-serif;
  letter-spacing: 0.03em;
  text-align: center;
  padding-top: 22px;
  margin-bottom: 0px;
}
@media screen and (min-width: 922px) {
  #page-document .TeaserListingTemplate .collection-slider-template .collectie-header .collectie_online,
  #page-edit .TeaserListingTemplate .collection-slider-template .collectie-header .collectie_online {
    line-height: 16px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection,
#page-edit .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection {
  display: block;
  font-size: 45px !important;
  margin-left: 40px;
  margin-right: 40px;
  line-height: 1;
  text-align: center !important;
  margin-top: 20px;
  margin-bottom: 33px;
  font-family: 'FranklinDmCp', Arial, sans-serif;
  color: #282931;
  letter-spacing: 0em;
  font-weight: 400;
  text-transform: none;
  border-bottom: 0 !important ;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection,
  #page-edit .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection {
    font-size: 80px !important;
    margin-bottom: 20px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection:hover,
#page-edit .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection:hover {
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection:hover,
  #page-edit .TeaserListingTemplate .collection-slider-template .collectie-header .browse_collection:hover {
    text-decoration: underline;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper {
  padding-bottom: 0px;
}
@media screen and (min-width: 768px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper {
    padding-bottom: 30px;
    display: flex;
  }
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper {
    flex-direction: column;
    padding-top: 9px;
    padding-bottom: 5px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper {
  position: relative;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper {
    display: flex;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper > a,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper > a {
  border-bottom: 0 !important;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.even .image-link,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.even .image-link {
    width: 55%;
    padding: 15px;
    padding-left: 55px;
  }
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.even #jaarverslag-title,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.even #jaarverslag-title {
    width: 45%;
    padding: 50px;
    padding-right: 75px;
    padding-top: 5vw;
    padding-bottom: 20px;
  }
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd {
    flex-direction: row-reverse;
  }
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd .image-link,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd .image-link {
    width: 55%;
    padding: 15px;
    padding-right: 55px;
  }
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd #jaarverslag-title,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd #jaarverslag-title {
    width: 45%;
    padding: 40px;
    padding-left: 55px;
    padding-top: 3vw;
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 1300px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.even #jaarverslag-title,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.even #jaarverslag-title {
    padding-top: 70px;
  }
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd #jaarverslag-title,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .image-description-wrapper.odd #jaarverslag-title {
    padding-top: 40px;
    padding-left: 56.5px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .slick-slider,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .slick-slider {
  position: relative !important;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .description p,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .description p {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 17px;
}
@media screen and (min-width: 992px) {
}
@media (max-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .item-description.dash,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .item-description.dash {
    display: none;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .listing-dates-wrapper,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .listing-dates-wrapper {
  padding-top: 11px;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .listing-dates-wrapper,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .listing-dates-wrapper {
    padding-top: 16px;
    padding-bottom: 3px;
  }
}
@media screen and (min-width: 1200px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .listing-dates-wrapper,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .listing-dates-wrapper {
    padding-top: 16px;
    padding-bottom: 3px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .hero-dates,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .hero-dates {
  color: #282931;
  font-family: 'FranklinMed', Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0.05em;
  line-height: 32px;
  padding-bottom: 12px;
  padding-top: 14px;
  text-transform: uppercase;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .expired .hero-dates,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .expired .hero-dates {
  text-decoration: line-through;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title {
  cursor: auto;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2 {
  color: #282931 !important;
  margin-top: 1.5px !important;
  border-bottom: 0px !important;
  margin-bottom: 5px !important;
  font-size: 32px;
  line-height: 40px !important;
  text-decoration: none !important;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2 {
    line-height: 37.5px !important;
    margin-top: 0px !important;
  }
}
@media screen and (min-width: 1300px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2 {
    margin-top: -1px !important;
    margin-bottom: 10px !important;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2 a:hover,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title h2 a:hover {
  text-decoration: underline !important;
  color: #282931 !important;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title a,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title a {
  color: #282931 !important;
  border-bottom: 0px !important;
  font-family: 'FranklinDmCp', Arial, sans-serif;
  font-size: 32px;
  letter-spacing: 0em !important;
  line-height: 40px !important;
  text-decoration: none !important;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title a,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title a {
    font-size: 5.5vw;
    line-height: 0.85 !important;
  }
}
@media screen and (min-width: 1300px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title a,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title a {
    font-size: 70px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title p,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title p {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #494a51;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 18.88px;
  letter-spacing: 0em;
  line-height: 30px !important;
}
@media screen and (min-width: 768px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title p,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title p {
    font-size: 19.68px;
  }
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title p,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title p {
    margin-top: 13px;
  }
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title .listing-dates,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title .listing-dates {
    padding-left: 3px;
    padding-bottom: 2px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title.item-title.private h2,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title.item-title.private h2,
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title.item-title.private a,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title.item-title.private a,
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title.item-title.private p,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper #jaarverslag-title.item-title.private p {
  color: red;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .showmore-button,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .showmore-button {
  margin-top: 25px;
  width: 135px;
  font-family: 'FranklinMed', Arial, sans-serif;
  display: block;
  padding: 17px;
  border: 1px solid #282931;
  border-radius: 0px;
  text-transform: none;
  height: auto;
  color: #282931;
  letter-spacing: 0.85px;
  line-height: 24.2857px;
  font-size: 17px;
  background-color: #ffffff;
  letter-spacing: 0.05em;
  text-shadow: 0px 0px 0px #ffffff;
  font-weight: 400;
  padding-bottom: 12px;
  cursor: pointer;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .showmore-button:hover,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .showmore-button:hover {
  background-color: #282931;
  color: white;
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title {
  margin-top: 16px !important;
  margin-bottom: 0px !important;
  font-size: 1.35em;
  line-height: 2px;
  font-family: 'FranklinMed', Arial, sans-serif !important;
  color: #494A51;
  letter-spacing: 0em;
  font-weight: 400;
  text-transform: none;
}
@media screen and (max-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title {
    padding-top: 0.1px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title p,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title p {
  font-family: 'FranklinMed', Arial, sans-serif !important;
  border-bottom: 0px !important;
  font-size: 20px;
  color: #282931;
  margin-bottom: 1px !important;
  margin-top: 8px;
  line-height: 30px;
}
@media screen and (min-width: 992px) {
  #page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title p,
  #page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title p {
    font-size: 23px;
  }
}
#page-document .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title:hover p,
#page-edit .TeaserListingTemplate .collection-slider-template .content-wrapper .plone-item-title:hover p {
  text-decoration: underline !important;
  text-underline-offset: 5px !important;
  text-decoration-thickness: 0.3px !important;
}
